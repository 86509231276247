import React, { useContext } from "react";

import { useTheme } from "@mui/styles";

import { Grid, Avatar, Typography } from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";

import useStripeInput from "../hooks/stripeInput";
import SlideLayout from "../layouts/SlideLayout";

import { StripeCustomerContext } from "../../helpers/contexts/stripeCustomer";

const CreditCardSlider = ({ setSliderOpen }) => {
  const { palette } = useTheme();

  const { paymentMethod } = useContext(StripeCustomerContext);
  const { paymentMethodId } = paymentMethod || {};

  const { input, button } = useStripeInput({
    onButtonClick: () => setSliderOpen(false),
  });

  return (
    <SlideLayout
      isSlideOpen
      setIsSlideOpen={setSliderOpen}
      header={
        <Grid container spacing={2}>
          <Grid item>
            <Avatar style={{ backgroundColor: palette.primary.main }}>
              <CreditCardIcon />
            </Avatar>
          </Grid>
          <Grid item>
            <Typography variant="h6">{`${
              paymentMethodId ? "Edit" : "Enter"
            } your billing data`}</Typography>
          </Grid>
        </Grid>
      }
      content={input}
      footer={
        <Grid container justifyContent="flex-end">
          <Grid item>{button}</Grid>
        </Grid>
      }
    />
  );
};
export default CreditCardSlider;
