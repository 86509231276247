import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import queryString from "query-string";

import AccountAccessLayout from "../layouts/AccountAccessLayout";

import AccountCreationForm from "./AccountCreationForm";

import { useExistingIndividualData } from "../../helpers/components/accountAccess";
import { analyticsPage } from "../../helpers/analytics";

const AccountCreation = () => {
  const { accountCreationType } = useParams();

  const [individualData, individualDataLoading] = useExistingIndividualData();

  const newAccountQueries = queryString.parse(window.location.search);
  const {
    companyId,
    inviteId,
    email: storedEmail,
    companyName,
  } = newAccountQueries || {};
  const isNewCompanyAdmin = !!companyId && !!inviteId && !!storedEmail;
  const newCompanyAdminProp = isNewCompanyAdmin && newAccountQueries;

  useEffect(() => {
    analyticsPage("account-creation");
  }, []);

  const buildPageTitleSubtitle = () => {
    if (accountCreationType !== "company") {
      return {
        title: "Track and offset your footprint",
        subtitle:
          "Create your free myAclymate account to track and offset your individual footprint.",
      };
    }

    if (isNewCompanyAdmin) {
      return {
        title: `Join ${companyName} on Aclymate to help them save the world.`,
        subtitle: `With Aclymate you'll be able to help ${companyName} easily track, reduce, and offsets their carbon footprint.`,
      };
    }

    return {
      title: `${
        companyName ? `Welcome ${companyName}, s` : "S"
      }tart your climate journey free for 33 days`,
      subtitle:
        "Create your free account to track and offset your company footprint.",
    };
  };

  const { title, subtitle } = buildPageTitleSubtitle();

  const accountAccessLayoutProps = {
    title,
    subtitle,
    formDataLoading: individualDataLoading,
    accountCreationType,
  };
  const accountCreationFormProps =
    accountCreationType === "company"
      ? {
          isNewCompanyAdmin: newCompanyAdminProp,
          companyId,
        }
      : {
          individualData,
        };

  return (
    <AccountAccessLayout {...accountAccessLayoutProps}>
      <AccountCreationForm {...accountCreationFormProps} />
    </AccountAccessLayout>
  );
};
export default AccountCreation;
