import React, { useState } from "react";

import { useTheme } from "@mui/styles";
import {
  Box,
  ButtonBase,
  Grid,
  Card as MuiCard,
  CardHeader,
  CardContent,
  Avatar,
  Skeleton,
  CardActions,
} from "@mui/material";

import ListItem from "./ListItem";
import Link from "./Link";

const LoadingSkeleton = ({ contentSkeleton, contentType }) => {
  if (contentSkeleton) {
    return contentSkeleton;
  }

  if (contentType === "list") {
    return [...new Array(3)].map((_, idx) => (
      <ListItem avatar isLoading key={`loading-list-item-${idx}`} />
    ));
  }

  return (
    <CardContent style={{ height: "80%", minHeight: "100px" }}>
      <Skeleton
        variant="rectangular"
        style={{ width: "100%", height: "100%", minHeight: "100px" }}
      />
    </CardContent>
  );
};

const CardComponent = ({
  avatar,
  avatarBackgroundColor,
  icon,
  title,
  titleTypographyProps,
  subtitle,
  subheaderTypographyProps,
  action,
  style,
  headerStyle,
  content,
  contentStyle,
  contentType,
  contentSkeleton,
  children,
  isLoading,
  colorType,
  noContent = false,
  headerButton,
  cardCta,
}) => {
  const { palette } = useTheme();

  const CardAvatar = () => {
    if (!icon && !avatar) {
      return <></>;
    }

    if (isLoading) {
      return (
        <Skeleton variant="circular">
          <Avatar />
        </Skeleton>
      );
    }

    return (
      avatar || (
        <Avatar
          style={{
            backgroundColor:
              avatarBackgroundColor ||
              palette[colorType]?.main ||
              palette.secondary.main,
            color: "white",
          }}
        >
          {icon}
        </Avatar>
      )
    );
  };

  const Header = () => (
    <CardHeader
      avatar={<CardAvatar />}
      titleTypographyProps={titleTypographyProps}
      subheaderTypographyProps={subheaderTypographyProps}
      subheader={
        subtitle && (isLoading ? <Skeleton variant="text" /> : subtitle)
      }
      title={isLoading ? <Skeleton /> : title}
      action={!isLoading && action}
      style={headerStyle}
    />
  );

  return (
    <MuiCard style={{ width: "100%", ...style }}>
      <Box
        display="flex"
        flexDirection="column"
        style={{ height: "100%", width: "100%" }}
      >
        {headerButton ? (
          <Link href="/platform/company/purchase">
            <ButtonBase style={{ textAlign: "left", color: "black" }}>
              <Header />
            </ButtonBase>
          </Link>
        ) : (
          (title || subtitle) && <Header />
        )}
        <Box flexGrow={1}>
          {isLoading && !noContent && (
            <LoadingSkeleton
              contentSkeleton={contentSkeleton}
              contentType={contentType}
            />
          )}
          {!isLoading && content && (
            <CardContent style={contentStyle}>{content}</CardContent>
          )}
          {!isLoading && children}
          {cardCta && (
            <CardActions style={{ justifyContent: "center" }}>
              {cardCta}
            </CardActions>
          )}
        </Box>
      </Box>
    </MuiCard>
  );
};

const Card = ({
  gridProps,
  button,
  href,
  onClick,
  cardType,
  colorType,
  isActive,
  titleStyle,
  ...otherProps
}) => {
  const { palette } = useTheme();
  const { main, light, dark } = palette[colorType] || {};
  const [hover, setHover] = useState(false);

  const cardTypes = {
    layout: {
      titleTypographyProps: { variant: "h3", ...titleStyle },
    },
    "colored-card": {
      titleTypographyProps: { variant: "h6" },
      subheaderTypographyProps: { variant: "subtitle2" },
      style: {
        height: "100%",
        backgroundColor: hover || isActive ? light : "white",
      },
      headerStyle: {
        height: "100%",
        color: hover || isActive ? "white" : "inherit",
      },
      avatarBackgroundColor: hover ? dark : main,
    },
  };

  const typeProps = cardTypes[cardType];

  if (button || cardType === "colored-card") {
    return (
      <Grid item {...gridProps}>
        <Link href={href}>
          <ButtonBase
            onClick={onClick}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{
              width: "100%",
              textAlign: "left",
              display: "block",
              height: "100%",
            }}
          >
            <CardComponent
              type={cardType}
              colorType={colorType}
              {...typeProps}
              {...otherProps}
            />
          </ButtonBase>
        </Link>
      </Grid>
    );
  }
  return (
    <Grid item {...gridProps}>
      <CardComponent
        type={cardType}
        colorType={colorType}
        {...typeProps}
        {...otherProps}
      />
    </Grid>
  );
};
export default Card;
