import React from "react";

import {
  XAxis,
  Label,
  YAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  Scatter,
  ScatterChart,
  ZAxis,
  ResponsiveContainer,
} from "recharts";

import { useTheme } from "@mui/styles";

const ViewScatterPlot = ({
  dataArray,
  setSelectedObject,
  xKey,
  xName,
  xLabel,
  yKey,
  yName,
  yLabel,
  zKey,
  zName,
  tooltipComponent,
  name,
  color,
}) => {
  const { palette } = useTheme();

  const handleScatterClick = ({ payload }) => setSelectedObject(payload.id);

  return (
    <ResponsiveContainer width="100%" aspect={4}>
      <ScatterChart
        width={400}
        height={400}
        margin={{
          top: 20,
          right: 30,
          bottom: 20,
          left: 25,
        }}
      >
        <CartesianGrid />
        <XAxis type="number" dataKey={xKey} name={xName} allowDecimals={false}>
          <Label value={xLabel} offset={5} position="bottom" />
        </XAxis>
        <YAxis type="number" dataKey={yKey} name={yName} allowDecimals={false}>
          <Label
            value={yLabel}
            position="left"
            offset={0}
            angle={-90}
            style={{ textAnchor: "middle" }}
          />
        </YAxis>
        <ZAxis
          dataKey={zKey}
          range={[40, 800]}
          name={zName}
          allowDecimals={false}
        />
        <ChartTooltip
          cursor={{ strokeDasharray: "3 3" }}
          labelFormatter={(value) => (value ? value : "")}
          content={tooltipComponent}
        />
        <Scatter
          name={name}
          data={dataArray}
          fill={palette[color].main}
          onClick={(event) => handleScatterClick(event)}
        />
      </ScatterChart>
    </ResponsiveContainer>
  );
};
export default ViewScatterPlot;
