import React, { useState, useRef } from "react";

import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import { Avatar, Box, Grid, Typography, Divider } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDollarSign,
  faHashtag,
  faExclamation,
  faShoppingBasket,
} from "@fortawesome/free-solid-svg-icons";

import { formatDecimal, formatDollars } from "@aclymatepackages/formatters";
import { useLayoutHelpers } from "@aclymatepackages/themes";
import { LoadingButton, Link } from "@aclymatepackages/atoms";
import PurchaseInputSection from "./PurchaseInputSection";

import PurchaseDialogLayout from "../layouts/PurchaseDialogLayout";

import { fireConfetti } from "../../helpers/components/purchase";
import { fetchOurApi } from "../../helpers/utils/apiCalls";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE);

const PaymentForm = (props) => {
  const [cardData, setCardData] = useState({});
  const { complete: isStripeComplete } = cardData;

  return (
    <PurchaseInputSection
      title="Please enter your credit card information"
      input={
        <PaymentElement
          options={{
            layout: "accordion",
            wallets: {
              applePay: "never",
              googlePay: "never",
            },
          }}
          onChange={(e) => setCardData(e)}
        />
      }
      nextStepDisabled={!isStripeComplete}
      {...props}
    />
  );
};

const ReviewPurchase = ({
  project,
  lbsToPurchase,
  purchaseDollars,
  stripeChargeId,
  setPurchaseComplete,
  paymentProcessLoading,
  setPaymentProcessLoading,
  purchaseCallback,
  ...otherProps
}) => {
  const { name: projectName } = project;

  const { theme } = useLayoutHelpers();
  const elements = useElements();
  const stripe = useStripe();

  const [paymentError, setPaymentError] = useState("");

  const processPayment = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setPaymentProcessLoading(true);

    const stripeResponse = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
    });

    const { error: stripeError } = stripeResponse;

    if (stripeError) {
      setPaymentProcessLoading(false);
      return setPaymentError(stripeError.message);
    }

    const { error: purchaseError, message } = await purchaseCallback();

    if (purchaseError) {
      setPaymentProcessLoading(false);
      return setPaymentError(message);
    }

    fireConfetti();
    return setPurchaseComplete(true);
  };

  const displayIcons = [
    {
      icon: <FontAwesomeIcon icon={faDollarSign} size="3x" />,
      title: formatDollars(purchaseDollars),
      subtitle:
        "This is the amount you’re contributing to this offset project.",
    },
    {
      icon: <FontAwesomeIcon icon={faHashtag} size="3x" />,
      title: `${formatDecimal(lbsToPurchase)}`,
      subtitle: "This is the number of pounds of emissions you’re offsetting.",
    },
  ];

  const disclaimers = [
    {
      icon: faExclamation,
      text: "All offset purchases are final.",
    },
    {
      icon: faShoppingBasket,
      text: `You’re offsetting your emissions by supporting the ${projectName} project.`,
    },
  ];

  return (
    <PurchaseInputSection
      title="Please review and confirm your purchase"
      input={
        <Grid container direction="column" spacing={2}>
          <Grid item container spacing={1} alignItems="stretch">
            {displayIcons.map(({ icon, title, subtitle }, idx) => (
              <Grid item key={`purchase-display-icon-${idx}`} sm={6} xs={12}>
                <Box
                  p={2}
                  style={{
                    background: theme.palette.backgroundGray.dark,
                  }}
                >
                  <Grid
                    container
                    spacing={1}
                    direction="column"
                    alignItems="center"
                  >
                    <Grid item>{icon}</Grid>
                    <Grid item>
                      <Typography
                        variant="h5"
                        align="center"
                        color="textPrimary"
                      >
                        {title}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="subtitle2"
                        align="center"
                        color="textSecondary"
                      >
                        {subtitle}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}
          </Grid>
          {disclaimers.map(({ icon, text }, idx) => (
            <Grid
              key={`disclaimer-${idx}`}
              item
              container
              spacing={2}
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item>
                <Avatar>
                  <FontAwesomeIcon icon={icon} />
                </Avatar>
              </Grid>
              <Grid item>
                <Typography variant="body2">{text}</Typography>
              </Grid>
            </Grid>
          ))}
          <Grid item container alignItems="center" direction="column">
            {paymentError && (
              <Grid item>
                <Typography align="center" variant="body1" color="error">
                  {paymentError}
                </Typography>
              </Grid>
            )}
            <Grid item>
              <LoadingButton
                isLoading={paymentProcessLoading}
                label="Purchase Offsets"
                onClick={processPayment}
                color="secondary"
              />
            </Grid>
          </Grid>
        </Grid>
      }
      {...otherProps}
    />
  );
};

const OffsetsPurchaseForm = ({
  project,
  maxPurchaseStep,
  setMaxPurchaseStep,
  inputData,
  setPurchaseComplete,
  stripeData,
  scrollableBoxRef,
  formBoxRef,
  dataEntryRows,
  purchaseCallback,
  noFooter = false,
  noPrice = false,
  noVolume = false,
  projectDescription,
}) => {
  const { availableCarbonLbs, totalThousandLbsCost } = project;
  const { lbsToPurchase, purchaseDollars } = inputData;
  const { paymentIntentDollars, stripeChargeId } = stripeData;

  const { theme } = useLayoutHelpers();

  const [paymentProcessLoading, setPaymentProcessLoading] = useState(false);

  const onFormBoxScroll = ({ target }) => {
    if (
      maxPurchaseStep !== 3 ||
      paymentIntentDollars === purchaseDollars ||
      paymentProcessLoading
    ) {
      return;
    }

    const scrollMax =
      formBoxRef.current.offsetHeight -
      scrollableBoxRef.current.offsetHeight -
      theme.spacing(2);

    const { scrollTop: scrollPosition } = target;

    if (scrollMax - scrollPosition < 50) {
      setPaymentProcessLoading(true);
      return fetchOurApi({
        path: "/stripe/update-payment-intent",
        method: "POST",
        data: {
          project,
          lbsToPurchase,
          stripeChargeId,
          description: projectDescription,
        },
        callback: () => setPaymentProcessLoading(false),
      });
    }
  };

  const purchaseSteps = [
    ...dataEntryRows,
    { Component: PaymentForm },
    {
      Component: ReviewPurchase,
      props: {
        project,
        purchaseCallback,
        setPurchaseComplete,
        paymentProcessLoading,
        setPaymentProcessLoading,
        ...stripeData,
        ...inputData,
      },
    },
  ];

  return (
    <PurchaseDialogLayout
      title="Support this offset project"
      subtitle={
        !noVolume &&
        `${formatDecimal(
          Math.round(availableCarbonLbs)
        )} pounds of carbon are available from this project.`
      }
      sideHeader={
        !noPrice && (
          <>
            <Typography variant="h6" color="textPrimary">
              {formatDollars(totalThousandLbsCost)}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              per 1000 lbs.
            </Typography>
          </>
        )
      }
      maxPurchaseStep={maxPurchaseStep}
      scrollableBoxRef={scrollableBoxRef}
      formBoxRef={formBoxRef}
      onScroll={onFormBoxScroll}
    >
      {[...new Array(maxPurchaseStep + 1)].map((_, idx) => {
        if (idx > maxPurchaseStep) {
          return <></>;
        }
        const { Component, props = {} } = purchaseSteps[idx];

        return (
          <Component
            key={`form-block-${idx}`}
            {...props}
            isMaxStep={
              maxPurchaseStep === idx && idx !== purchaseSteps.length - 1
            }
            setMaxPurchaseStep={setMaxPurchaseStep}
          />
        );
      })}
      {!noFooter && (
        <>
          <Divider style={{ height: "1px" }} />
          <Box p={1} pt={1}>
            <Typography variant="caption" display="block" align="center">
              Already have an account?{" "}
              <Link href="https://aclymate.app/">
                Sign in to buy your offsets
              </Link>
            </Typography>
          </Box>
        </>
      )}
    </PurchaseDialogLayout>
  );
};

const OffsetsPurchaseFlow = ({
  project,
  thanksStep,
  dataEntryRows,
  purchaseCallback,
  inputData,
  stripeData,
  ...otherProps
}) => {
  const [maxPurchaseStep, setMaxPurchaseStep] = useState(0);
  const [purchaseComplete, setPurchaseComplete] = useState(false);

  const { clientSecret } = stripeData;

  const scrollableBoxRef = useRef();
  const formBoxRef = useRef();

  const formProps = {
    project,
    maxPurchaseStep: clientSecret ? maxPurchaseStep : 0,
    setMaxPurchaseStep,
    inputData,
    setPurchaseComplete,
    stripeData,
    scrollableBoxRef,
    formBoxRef,
    dataEntryRows,
    purchaseCallback,
    ...otherProps,
  };

  return (
    <>
      {purchaseComplete ? (
        thanksStep
      ) : clientSecret ? (
        <Elements
          options={{
            clientSecret,
            appearance: {
              theme: "stripe",
            },
          }}
          stripe={stripePromise}
        >
          <OffsetsPurchaseForm {...formProps} />
        </Elements>
      ) : (
        <OffsetsPurchaseForm {...formProps} />
      )}
    </>
  );
};
export default OffsetsPurchaseFlow;
