import React, { useEffect, useState } from "react";

import {
  Grid,
  Button,
  List,
  CircularProgress,
  Avatar,
  Tooltip,
  Typography,
} from "@mui/material";

import ReceiptIcon from "@mui/icons-material/Receipt";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import RefreshIcon from "@mui/icons-material/Refresh";

import { formatDate } from "@aclymatepackages/formatters";

import Card from "../../atoms/mui/Card";
import ListItem from "../../atoms/mui/ListItem";

import {
  useAccountData,
  useCollectionDataListener,
  useCachedFirebaseCrud,
} from "../../../helpers/firebase";
import { sortOptionsAlphabetically } from "../../../helpers/otherHelpers";
import { fetchOurApi } from "../../../helpers/utils/apiCalls";
import { useQuickbooks } from "../../../helpers/integrations/quickbooks";
import {
  useStarterTierSubscriptionFlags,
  useSubscriptionType,
} from "../../../helpers/hooks/companyData";

const AccountingIntegrationItem = ({
  integrationName,
  integrationLogo,
  connectionId,
  isQuickbooks,
  status,
  pullDate,
  fetchQuickbooksData,
  signOutQuickbooks,
}) => {
  const accountId = window.sessionStorage.getItem("accountId");
  const [accountingTasks] = useCollectionDataListener("tasks", [
    ["type", "==", "accounting"],
  ]);

  const [isDeletingIntegration, setIsDeletingIntegration] = useState(false);
  const [isDeletingIntegrationInProgress, setIsDeletingIntegrationInProgress] =
    useState(false);
  const [isRefreshingCallInProgress, setIsRefreshingCallInProgress] =
    useState(false);

  const isDataRefreshing =
    isRefreshingCallInProgress ||
    status === "refreshing" ||
    accountingTasks.find(({ status }) =>
      ["uploading", "running"].includes(status)
    );

  const generateSecondaryText = () => {
    if (isDeletingIntegration) {
      return "This will prevent us from making any further data pulls.";
    }

    if (pullDate) {
      return `Latest transactions sync occured on ${formatDate(pullDate)}.`;
    }

    return "Transactions have not been pulled into Aclymate yet.";
  };

  const deleteIntegrationConnection = () => {
    setIsDeletingIntegrationInProgress(true);

    if (isQuickbooks) {
      return signOutQuickbooks();
    }

    return fetchOurApi({
      path: "/integrations/codat/delete-integration-connection",
      method: "POST",
      data: { accountId, connectionId },
    });
  };

  const refreshDataCall = async () => {
    setIsRefreshingCallInProgress(true);

    if (isQuickbooks) {
      await fetchQuickbooksData();

      return setIsRefreshingCallInProgress(false);
    }

    await fetchOurApi({
      path: "/integrations/codat/refresh-bank-transactions-data",
      method: "POST",
      data: { accountId, connectionId },
    });

    return setIsRefreshingCallInProgress(false);
  };

  const generateActions = () => {
    if (isDeletingIntegrationInProgress || isDataRefreshing) {
      return [
        {
          tooltip: `${
            isDataRefreshing ? "Data fetch" : "Deletion"
          } in progress...`,
          icon: <CircularProgress />,
        },
      ];
    }

    if (isDataRefreshing) {
      return [
        {
          tooltip: "Refresh in progress...",
          icon: <CircularProgress />,
        },
      ];
    }

    if (isDeletingIntegration) {
      return [
        {
          tooltip: "Confirm deletion",
          onClick: () => deleteIntegrationConnection(),
          icon: <CheckCircleIcon />,
        },
        {
          tooltip: "Cancel deletion",
          onClick: () => setIsDeletingIntegration(false),
          icon: <CancelIcon />,
        },
      ];
    }

    return [
      {
        tooltip: "Refresh data",
        onClick: () => refreshDataCall(),
        icon: <RefreshIcon />,
      },
      {
        tooltip: "Delete connection",
        onClick: () => setIsDeletingIntegration(true),
        icon: <LinkOffIcon />,
      },
    ];
  };

  return (
    <ListItem
      primaryText={
        isDeletingIntegration
          ? "Are you sure you would like to delete this integration?"
          : integrationName
      }
      secondaryText={generateSecondaryText()}
      avatarImg={integrationLogo}
      actions={generateActions()}
    />
  );
};

const AccountingIntegrationIcon = ({
  name,
  iconLogoUrl,
  platformKey,
  setIsCodatIntegrationLinkOpening,
  launchQuickbooks,
}) => {
  const accountId = window.sessionStorage.getItem("accountId");
  const [{ name: companyName, codatCompanyId: existingCodatCompanyId }] =
    useAccountData();
  const { updateAccountData } = useCachedFirebaseCrud();
  const [{ isPaid }] = useSubscriptionType("saas");
  const { isStarterTier } = useStarterTierSubscriptionFlags();

  const onIntegrationIconClick = async () => {
    setIsCodatIntegrationLinkOpening(true);

    if (name === "QuickBooks Online" || name === "QuickBooks Online Sandbox") {
      await launchQuickbooks();

      return setIsCodatIntegrationLinkOpening(false);
    }

    if (!existingCodatCompanyId) {
      const codatCompanyId = await fetchOurApi({
        path: "/integrations/codat/create-company",
        method: "POST",
        data: { companyName },
        callback: ({ codatCompanyId }) => codatCompanyId,
      });

      await updateAccountData({ codatCompanyId });
    }

    const connectionLinkUrl = await fetchOurApi({
      path: "/integrations/codat/create-integration-connection",
      method: "POST",
      data: { accountId, platformKey },
      callback: (res) => res,
    });

    window.open(connectionLinkUrl, "_blank");

    return setIsCodatIntegrationLinkOpening(false);
  };

  return (
    <Grid item>
      <Tooltip title={name}>
        <span>
          <Button
            onClick={() => onIntegrationIconClick()}
            disabled={!isPaid || isStarterTier}
          >
            <Avatar src={iconLogoUrl} />
          </Button>
        </span>
      </Tooltip>
    </Grid>
  );
};

const AccountingCard = ({ gridProps = { sm: 6 } }) => {
  const [activeCodatIntegrations, activeCodatIntegrationsLoading] =
    useCollectionDataListener("integrations", [["type", "==", "codat"]]);

  const [availableCodatIntegrations, setAvailableCodatIntegrations] = useState(
    []
  );
  const [isCodatIntegrationLinkOpening, setIsCodatIntegrationLinkOpening] =
    useState(false);
  const [quickbooksError, setQuickbooksError] = useState("");

  const {
    pullDate,
    tokenLoading,
    launchQuickbooks,
    fetchQuickbooksData,
    signOutQuickbooks,
  } = useQuickbooks({ setQuickbooksError });

  useEffect(() => {
    if (!availableCodatIntegrations.length) {
      fetchOurApi({
        path: "/integrations/codat/get-accounting-integrations",
        method: "POST",
        callback: (res) => setAvailableCodatIntegrations(res),
      });
    }
  }, [availableCodatIntegrations]);

  const generateCardContent = () => {
    if (activeCodatIntegrations.length || pullDate) {
      return (
        <List>
          {pullDate && (
            <AccountingIntegrationItem
              integrationName={`QuickBooks Online${
                process.env.NODE_ENV !== "production" ? " Sandbox" : ""
              }`}
              integrationLogo="/images/quickbooksLogo.png"
              pullDate={pullDate}
              fetchQuickbooksData={fetchQuickbooksData}
              signOutQuickbooks={signOutQuickbooks}
              isQuickbooks
            />
          )}
          {activeCodatIntegrations.map((integration) => (
            <AccountingIntegrationItem {...integration} />
          ))}
        </List>
      );
    }

    if (isCodatIntegrationLinkOpening) {
      return (
        <Grid item container justifyContent="center">
          <CircularProgress />
        </Grid>
      );
    }

    return (
      <Grid container spacing={2} justifyContent="center" direction="column">
        <Grid item container spacing={2} justifyContent="center">
          {availableCodatIntegrations?.length &&
            sortOptionsAlphabetically(availableCodatIntegrations).map(
              (codatIntegration) => (
                <AccountingIntegrationIcon
                  {...codatIntegration}
                  setIsCodatIntegrationLinkOpening={
                    setIsCodatIntegrationLinkOpening
                  }
                  launchQuickbooks={launchQuickbooks}
                />
              )
            )}
        </Grid>
        {quickbooksError && (
          <Grid item>
            <Typography variant="body2" align="center" color="error">
              {quickbooksError}
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <Card
      gridProps={gridProps}
      isLoading={
        activeCodatIntegrationsLoading ||
        tokenLoading ||
        !availableCodatIntegrations.length
      }
      cardType="layout"
      title="Accounting"
      subtitle="We can connect to your accounting software for faster setup"
      icon={<ReceiptIcon />}
      content={generateCardContent()}
    />
  );
};
export default AccountingCard;
