import React, { useContext, useState } from "react";

import {
  Grid,
  Typography,
  Paper,
  Button,
  TableRow,
  TableCell,
  Tooltip,
  useTheme,
  Box,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faTriangleExclamation,
  faCalendarDay,
  faPeopleRoof,
  faCalendarCheck,
} from "@fortawesome/free-solid-svg-icons";

import { hexToRgba } from "@aclymatepackages/converters";
import mainTheme from "@aclymatepackages/themes";
import { formatDate, formatDecimal } from "@aclymatepackages/formatters";
import { subcategoriesWithColors } from "@aclymatepackages/subcategories";

import NewEventForm from "./NewEventForm";
import EventDetailsSlider from "./EventDetailsSlider";

import EditRowTransactions from "../dashboard/EditRowTransactions";
import LoadingCardLayoutBox from "../../layouts/LoadingCardLayoutBox";
import DashboardViewLayout from "../../layouts/DashboardViewLayout";
import SortableTable, {
  makeColumnObj,
} from "../../modules/tables/SortableTable";
import ViewBarChart from "../../modules/charts/ViewBarChart";
import EmissionsCustomTooltip from "../../modules/charts/EmissionsCustomTooltip";
import ViewGraphCard from "../../layouts/ViewGraphCard";

import useStatusFilterChips from "../../hooks/statusFilterChips";

import {
  calculateEmployeeEmissions,
  useDisplayEvents,
} from "../../../helpers/components/events";
import { PlatformLayoutContext } from "../../../helpers/contexts/platformLayout";

const eventsStatuses = {
  past: {
    id: "past",
    icon: faTriangleExclamation,
    tooltip: `This event has passed and needs to be closed.`,
    color: mainTheme.palette.error.main,
    name: "Past",
    severity: 3,
  },
  inProgress: {
    id: "inProgress",
    icon: faPeopleRoof,
    tooltip: "This event is currently in progress",
    name: "In Progress",
    severity: 2,
    color: mainTheme.palette.primary.dark,
  },
  upcoming: {
    id: "upcoming",
    icon: faCalendarDay,
    tooltip: "This event is scheduled to happen in the future.",
    name: "Upcoming",
    severity: 1,
    color: mainTheme.palette.primary.main,
  },
  closed: {
    id: "closed",
    icon: faCalendarCheck,
    tooltip: "This event has been closed and added to your accounting",
    name: "Closed",
    severity: 0,
    color: mainTheme.palette.secondary.main,
  },
};

const getEmissionsData = (event) => {
  const { employees = [] } = event;

  const employeeEmissions = calculateEmployeeEmissions(employees);

  const {
    name,
    venueElectricTons,
    venueGasTons,
    attendeeEmissionsTons,
    transactionSubcategoriesTons,
  } = event;

  return {
    name,
    electricity: venueElectricTons,
    gas: venueGasTons,
    travel: attendeeEmissionsTons,
    employees: employeeEmissions,
    ...transactionSubcategoriesTons,
  };
};

const getSubcategoryBarAreas = ({ transactionSubcategoriesTons }) =>
  Object.keys(transactionSubcategoriesTons).map((subcategory) => {
    const subcategoryData = subcategoriesWithColors.find(
      ({ subcategory: subcategoryName }) => subcategoryName === subcategory
    );
    return {
      name: subcategoryData?.name,
      dataKey: subcategory,
      subcategory,
      color: subcategoryData?.color,
    };
  });

const NoEventsLayout = ({ setShowNewEventForm }) => (
  <Grid container spacing={2} alignItems="stretch">
    <Grid item xs={6} container direction="column" spacing={4}>
      <Grid item>
        <Typography variant="h2" paragraph>
          Events are fun - let's make them good for the planet too.
        </Typography>
        <Typography variant="h5">
          With Aclymate's new event tools and reporting, your events can stand
          out as sustainable, and your audience can feel engaged knowing they're
          doing their part.
        </Typography>
      </Grid>
      <Grid item container justifyContent="center">
        <Grid item>
          <Button
            size="large"
            variant="contained"
            color="secondary"
            onClick={() => setShowNewEventForm(true)}
          >
            Create your first event
          </Button>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={6}>
      <Paper
        style={{
          height: "100%",
          width: "100%",
          backgroundImage: "url('/images/platform/events-splash.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      />
    </Grid>
  </Grid>
);

const EventsTableRow = ({
  status,
  name,
  location,
  startDate,
  endDate,
  tonsCo2e,
  onClick,
}) => {
  const { convertCarbonUnits } = useContext(PlatformLayoutContext);

  const { icon, tooltip, color } = eventsStatuses[status] || {};

  const cells = [
    <Tooltip title={tooltip}>
      <div>
        <FontAwesomeIcon icon={icon} size="2x" style={{ color }} />
      </div>
    </Tooltip>,
    name,
    location,
    `${formatDate(startDate)}-${formatDate(endDate)}`,
    formatDecimal(convertCarbonUnits(tonsCo2e)),
  ];

  return (
    <TableRow
      onClick={onClick}
      sx={{
        cursor: "pointer",
      }}
    >
      {cells.map((cell, idx) => (
        <TableCell key={`table-cell-${idx}`}>{cell}</TableCell>
      ))}
    </TableRow>
  );
};

const EventsBarGraph = ({ events, setSelectedEvent }) => {
  const { palette } = useTheme();
  const { convertCarbonUnits } = useContext(PlatformLayoutContext);

  const convertEventCarbonUnits = (events) =>
    events.map((event) =>
      Object.keys(event).reduce((acc, key) => {
        acc[key] = key === "name" ? event[key] : convertCarbonUnits(event[key]);
        return acc;
      }, {})
    );

  const eventsWithEmissions = events.map((event) => getEmissionsData(event));

  const eventsWithConvertedEmissions =
    convertEventCarbonUnits(eventsWithEmissions);
  const eventsWithLabels = eventsWithConvertedEmissions.map(
    ({ name, ...otherProps }) => ({ name, label: name, ...otherProps })
  );

  const baseBarAreas = [
    {
      name: "Venue Electricity",
      dataKey: "electricity",
      subcategory: "electricity",
      color: palette.electricity.main,
    },
    {
      name: "Venue Gas",
      dataKey: "gas",
      subcategory: "gas",
      color: palette.gas.main,
    },
    {
      name: "Attendee Emissions",
      dataKey: "travel",
      subcategory: "travel",
      color: palette.travel.main,
    },
    {
      name: "Employee Emissions",
      dataKey: "employees",
      subcategory: "employees",
      color: palette.employees.main,
    },
  ];

  const subcategoryBarAreas = events.flatMap((event) =>
    getSubcategoryBarAreas(event)
  );

  const uniqueSubcategoryBarAreas = subcategoryBarAreas.filter(
    (item, index, self) =>
      self.findIndex((i) => i.subcategory === item.subcategory) === index
  );

  const barAreasArrayWithSubcategories = [
    ...baseBarAreas,
    ...uniqueSubcategoryBarAreas,
  ];

  const handleSelectedEventFromBar = (events) => (eventName) => {
    const selectedEvent = events.find(({ name }) => name === eventName);

    return setSelectedEvent(selectedEvent);
  };

  const EventsChart = ({ dataArray, setSelectedObject }) => (
    <ViewBarChart
      dataType="events"
      setSelectedObject={setSelectedObject}
      data={dataArray}
      barAreasArray={barAreasArrayWithSubcategories}
      tooltipComponent={
        <EmissionsCustomTooltip
          categoriesArray={barAreasArrayWithSubcategories}
          dataArray={dataArray}
        />
      }
    />
  );

  const graphs = [
    {
      title: `Event Emissions`,
      type: "bar",
      Graph: EventsChart,
      graphProps: { setSelectedObject: handleSelectedEventFromBar(events) },
      sortable: true,
    },
  ];

  return <ViewGraphCard graphs={graphs} dataArray={eventsWithLabels} />;
};

const DisplayEvents = ({ events, openNewEventForm }) => {
  const { displayUnitLabel } = useContext(PlatformLayoutContext);

  const { filterChips, filterByChips } = useStatusFilterChips(
    events,
    eventsStatuses
  );

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const eventsTableColumns = [
    makeColumnObj("STATUS", "status", true),
    makeColumnObj("NAME", "name", true),
    makeColumnObj("LOCATION", "location", true),
    makeColumnObj("DATES", "startDate", true),
    makeColumnObj(`${displayUnitLabel.toUpperCase()} CO2`, "tonsCo2e", true),
  ];

  const filteredEvents = events.filter(filterByChips);

  return (
    <>
      {selectedEvent && (
        <EventDetailsSlider
          selectedEvent={selectedEvent}
          setSelectedEvent={setSelectedEvent}
          selectedTransaction={selectedTransaction}
          setSelectedTransaction={setSelectedTransaction}
          closeSelectedEvent={() => setSelectedEvent(null)}
        />
      )}
      {selectedTransaction && (
        <EditRowTransactions
          transaction={selectedTransaction || {}}
          setIsSlideOpen={setSelectedTransaction}
          saveButtonText="Save Changes"
        />
      )}
      <Box
        sx={{
          height: "100%",
          width: "100%",
        }}
      >
        <DashboardViewLayout
          type="events"
          title="Events"
          chips={filterChips}
          showFilterButton={false}
          primaryAction={
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={openNewEventForm}
            >
              Create New Event
            </Button>
          }
          table={
            <SortableTable
              rows={[...filteredEvents].sort((a, b) => b.severity - a.severity)}
              rowComponent={(event) => (
                <EventsTableRow
                  key={`event-row-${event.id}`}
                  {...event}
                  onClick={() => setSelectedEvent(event)}
                />
              )}
              columns={eventsTableColumns}
            />
          }
          graph={
            <EventsBarGraph
              events={filteredEvents}
              setSelectedEvent={setSelectedEvent}
            />
          }
        />
      </Box>
    </>
  );
};

const Events = () => {
  const { palette } = useTheme();

  const [events, eventsLoading] = useDisplayEvents();

  const [showNewEventForm, setShowNewEventForm] = useState(false);

  return (
    <>
      {showNewEventForm && (
        <NewEventForm setShowNewEventForm={setShowNewEventForm} />
      )}
      <LoadingCardLayoutBox
        style={{
          backgroundColor: hexToRgba(palette.events.main, 0.1),
          display: "flex",
          alignItems: "center",
          minHeight: "100%",
        }}
        isLoading={eventsLoading}
      >
        {!!events.length ? (
          <DisplayEvents
            events={events}
            openNewEventForm={() => setShowNewEventForm(true)}
          />
        ) : (
          <NoEventsLayout setShowNewEventForm={setShowNewEventForm} />
        )}
      </LoadingCardLayoutBox>
    </>
  );
};
export default Events;
