import React, { useState, useContext } from "react";

import { useTheme } from "@mui/styles";
import {
  Box,
  Grid,
  Typography,
  Avatar,
  Button,
  ButtonBase,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";

import { AnimatedLogo, DefaultPaper } from "@aclymatepackages/atoms";
import { hexToRgba } from "@aclymatepackages/converters";

import FormDivider from "../forms/FormDivider";
import MultiPartFormReveal from "../forms/MultiPartFormReveal";

import Backdrop from "../../atoms/mui/Backdrop";

import {
  MultipartFormContext,
  MultipartFormContextProvider,
} from "../../../helpers/contexts/multiPartForm";
import { useLayoutHelpers } from "../../../helpers/otherHelpers";
import { useSharedFormLoading } from "../../../helpers/components/inputs";
import useInputType from "../../../helpers/components/display-lists/inputTypes";

const OptionCard = ({ option, chooseOption, isSelected }) => {
  const { title, subtitle, IconComponent, value, onClick } = option;

  const { color } = useContext(MultipartFormContext);

  const theme = useTheme();

  const borderStyleObj = isSelected ? { border: `thick solid ${color}` } : {};

  return (
    <ButtonBase
      onClick={() => (onClick ? onClick() : chooseOption(value))}
      style={{ height: "100%", width: "100%" }}
    >
      <DefaultPaper
        style={{
          height: "100%",
          width: "100%",
          position: "relative",
          ...borderStyleObj,
        }}
      >
        {isSelected && (
          <div
            style={{
              backgroundColor: color,
              position: "absolute",
              right: 0,
              top: 0,
              padding: `calc(${theme.spacing(1)} / 2)`,
              borderRadius: "0 0 0 4px",
              color: "white",
            }}
          >
            <DoneIcon />
          </div>
        )}
        <Typography variant="body1">{title}</Typography>
        {IconComponent && <IconComponent style={{ color }} fontSize="large" />}
        {subtitle && (
          <Typography variant="caption" color="textSecondary" display="block">
            {subtitle}
          </Typography>
        )}
      </DefaultPaper>
    </ButtonBase>
  );
};

const ProgressBlockBar = ({ formsLength, formStep, idx, type }) => {
  const theme = useTheme();
  const { color } = useInputType(type);

  const colorizeBlock = () => {
    if (idx > formStep) {
      return "rgba(255, 255, 255, 0.7)";
    }
    if (idx === formStep) {
      return color;
    }
    return hexToRgba(color, 0.7);
  };

  return (
    <Grid item>
      <Box
        style={{
          backgroundColor: colorizeBlock(),
          width: `${720 / formsLength}px`,
          height: theme.spacing(1),
        }}
      />
    </Grid>
  );
};

const MultiPartFormProgressBar = ({ type, forms, formStep }) => (
  <Box p={4} display="flex" justifyContent="center" flexGrow={0}>
    <Grid container spacing={1}>
      {forms.map((_, idx) => (
        <ProgressBlockBar
          key={`progress-bar-block-${idx}`}
          idx={idx}
          formStep={formStep}
          formsLength={forms.length}
          type={type}
        />
      ))}
    </Grid>
  </Box>
);

const MultiPartFormLayout = ({
  type,
  forms = [],
  style,
  selectedOption,
  setOption,
  onClose,
  confirmClose = true,
  confirmCloseMessage = "Are you sure you want to exit this process?",
  submitLoadingText,
  showProgress,
}) => {
  const { isMobile } = useLayoutHelpers();
  const { formLoading } = useSharedFormLoading();
  const { formBottomRef } = useContext(MultipartFormContext);
  const {
    title,
    subtitle,
    color,
    backdropColor,
    primaryIcon: PrimaryIcon,
    options,
    customIcon,
    customOptionsHeader,
    showIntro,
  } = useInputType(type);

  const [formStep, setFormStep] = useState(1);
  const [clearedIntro, setClearedIntro] = useState(!showIntro);

  const shouldShowFormBlocks = () => {
    if (!options) {
      return clearedIntro;
    }
    return !!selectedOption;
  };

  const showFormBlocks = shouldShowFormBlocks();

  return (
    <Backdrop
      open
      style={{
        zIndex: 1300,
        color: "white",
        overflowY: "auto",
        overflowX: "hidden",
        ...style,
      }}
      onClose={onClose}
      confirmClose={confirmClose}
      confirmCloseMessage={confirmCloseMessage}
      backgroundColor={backdropColor && hexToRgba(backdropColor, 0.85)}
    >
      {formLoading ? (
        <Grid
          container
          direction="column"
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <AnimatedLogo />
          </Grid>
          <Grid item>
            <Typography variant="h6" align="center">
              {submitLoadingText}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Box
          style={{
            height: "100%",
            width: "100%",
            boxSizing: "content-box",
            overflow: "hidden",
          }}
          position="relative"
          display="flex"
          alignItems="center"
          flexDirection="column"
          pb={isMobile ? 4 : 0}
        >
          <Box
            flexGrow={1}
            p={4}
            style={{
              maxHeight: "100%",
              boxSizing: "border-box",
              overflowY: "auto",
              overflowX: "hidden",
              width: "100%",
            }}
            id="scroll-blocker"
          >
            <Grid container direction="column" spacing={4}>
              <Grid
                item
                container
                direction="column"
                spacing={1}
                alignItems="center"
              >
                <Grid item>
                  {customIcon || (
                    <Avatar
                      style={{
                        backgroundColor: color,
                        height: 100,
                        width: 100,
                      }}
                    >
                      <PrimaryIcon style={{ fontSize: "4rem" }} />
                    </Avatar>
                  )}
                </Grid>
                <Grid item sm={8}>
                  <Typography variant="h3" color="inherit" align="center">
                    {title}
                  </Typography>
                </Grid>
                {subtitle && (
                  <Grid item sm={6}>
                    <Typography variant="h6" color="inherit" align="center">
                      {subtitle}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              {options && !selectedOption && (
                <Grid container item direction="column" spacing={2}>
                  <Grid item>
                    <Typography variant="h5" align="center" color="inherit">
                      {customOptionsHeader ||
                        `How would you like to add your ${type}?`}
                    </Typography>
                  </Grid>
                  <Grid item container spacing={2} justifyContent="center">
                    {options.map((option, idx) => (
                      <Grid
                        key={`input-option-${idx}`}
                        item
                        xs={options.length > 3 ? 3 : 4}
                      >
                        <OptionCard
                          chooseOption={(value) => setOption(value)}
                          option={option}
                          isSelected={option.value === selectedOption}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              )}
              {!clearedIntro && (
                <Grid item container justifyContent="center">
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setClearedIntro(true)}
                    >
                      Get Started
                    </Button>
                  </Grid>
                </Grid>
              )}
              {options && selectedOption && <FormDivider />}
              {showFormBlocks && (
                <MultiPartFormReveal
                  formStep={formStep}
                  setFormStep={setFormStep}
                  forms={forms}
                />
              )}
              <div ref={formBottomRef} />
            </Grid>
          </Box>
          {showProgress && showFormBlocks && (
            <MultiPartFormProgressBar
              forms={forms}
              formStep={formStep - 1}
              type={type}
            />
          )}
        </Box>
      )}
    </Backdrop>
  );
};

const MultiPartForm = ({ type, ...otherProps }) => (
  <MultipartFormContextProvider type={type}>
    <MultiPartFormLayout type={type} {...otherProps} />
  </MultipartFormContextProvider>
);

export default MultiPartForm;
