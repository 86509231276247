import React, { useState, useContext } from "react";

import { useTheme } from "@mui/styles";
import {
  Box,
  Grid,
  Typography,
  Button,
  Tooltip,
  ButtonBase,
  Paper,
  Menu,
  MenuItem,
  ListItemAvatar,
  ListItemText,
  ThemeProvider,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import { kgsToTons } from "@aclymatepackages/converters";
import { formatDecimal, letterSBoolean } from "@aclymatepackages/formatters";
import {
  TextField,
  LoadingButton,
  ToggleButtons,
  CategoriesAvatar,
  DefaultPaper,
} from "@aclymatepackages/atoms";
import findSubcategory, {
  subcategories,
} from "@aclymatepackages/subcategories";
import { mergeDarkTheme } from "@aclymatepackages/themes";

import useFuelInput from "../../inputs/emissions/FuelInputBlock";
import useRentalCarInput from "../../inputs/emissions/RentalCarInputBlock";
import useFlightsInput from "../../inputs/emissions/FlightsInputBlock";
import useCombinedUtilitiesInput from "../../inputs/emissions/CombinedUtilitiesInputBlock";
import useElectricityInput from "../../inputs/emissions/ElectricityInputBlock";
import useGasInput from "../../inputs/emissions/GasInputBlock";
import useRidesInput from "../../inputs/emissions/RidesInputBlock";
import useShippingInput from "../../inputs/emissions/ShippingInputBlock";
import useMileageInput from "../../inputs/emissions/MileageInputBlock";
import useHotelInput from "../../inputs/emissions/HotelInputBlock";
import useWasteInput from "../../inputs/emissions/WasteInputBlock";
import useEquipmentFuelInput from "../../inputs/emissions/EquipmentFuelInputBlock";
import useSpendBasedInput from "../../inputs/emissions/SpendBasedInputBlock";
import useTrainInput from "../../inputs/emissions/TrainsInputBlock";

import TransactionsTagsInputs from "../../inputs/tags/TransactionTagsInputs";

import DatePicker from "../../atoms/mui/DatePicker";
import SlideLayout from "../../layouts/SlideLayout";

import {
  useAccountData,
  useCachedDisplayData,
} from "../../../helpers/firebase";
import { buildFormattedTagsObjs } from "../../../helpers/components/tags";
import { PlatformLayoutContext } from "../../../helpers/contexts/platformLayout";
import useAccountingData from "../../../helpers/hooks/accountingData";

const InputCategoriesMenu = ({
  categoriesMenuAnchorEl,
  setCategoriesMenuAnchorEl,
  onSubcategoryChange,
  transactionSubcategory,
  anchorOrigin = { vertical: "top", horizontal: "left" },
}) => {
  const inputSubcategories = subcategories.filter(({ input }) => input);

  return (
    <Menu
      open={!!categoriesMenuAnchorEl}
      anchorEl={categoriesMenuAnchorEl}
      onClose={() => setCategoriesMenuAnchorEl(null)}
      anchorOrigin={anchorOrigin}
    >
      {inputSubcategories.map(({ name, subcategory }) => (
        <MenuItem
          key={`${subcategory}-menu-item`}
          selected={subcategory === transactionSubcategory}
          onClick={() => onSubcategoryChange(subcategory)}
        >
          <ListItemAvatar>
            <CategoriesAvatar subcategory={subcategory} />
          </ListItemAvatar>
          <ListItemText>{name}</ListItemText>
        </MenuItem>
      ))}
    </Menu>
  );
};

const TransactionFooterRow = ({
  saveEnabled,
  saveTransaction,
  acceptText = "Accept",
  tonsCo2e,
  onRejectTransaction,
  calcLoading,
  alert,
}) => {
  const { palette } = useTheme();

  const { convertCarbonUnits, displayUnitLabel } = useContext(
    PlatformLayoutContext
  );

  const displayCarbonVolume = (tonsCo2e) => {
    if (isNaN(tonsCo2e) || !tonsCo2e) {
      return 0;
    }

    const displayVolume = convertCarbonUnits(Math.abs(tonsCo2e));

    if (displayVolume >= 100) {
      return formatDecimal(displayVolume);
    }

    return displayVolume.toFixed(2);
  };

  return (
    <Grid container direction="column" spacing={2}>
      {alert && (
        <Grid item>
          <DefaultPaper style={{ backgroundColor: palette.error.main }}>
            <ThemeProvider theme={mergeDarkTheme}>
              <Grid container spacing={2} wrap="nowrap" alignItems="center">
                <Grid item>
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    size="2x"
                    style={{ color: "white" }}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" color="textPrimary">
                    {alert}
                  </Typography>
                </Grid>
              </Grid>
            </ThemeProvider>
          </DefaultPaper>
        </Grid>
      )}
      <Grid
        item
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Typography variant="h4">{`${displayCarbonVolume(
                tonsCo2e
              )} ${displayUnitLabel} CO2`}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2} alignItems="center">
            {onRejectTransaction && (
              <Grid item>
                <Button variant="contained" onClick={onRejectTransaction}>
                  Reject
                </Button>
              </Grid>
            )}
            <Grid item>
              <LoadingButton
                label={acceptText}
                color="secondary"
                onClick={saveTransaction}
                disabled={!saveEnabled}
                isLoading={calcLoading}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const HeaderDisplay = ({ transactionSubcategory }) => {
  const selectedSubcategoryName =
    subcategories.find(
      ({ subcategory }) => subcategory === transactionSubcategory
    )?.name || "Other Emissions";

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        <CategoriesAvatar subcategory={transactionSubcategory} />
      </Grid>
      <Grid item>
        <Typography variant="body1">{selectedSubcategoryName}</Typography>
      </Grid>
    </Grid>
  );
};

const IconSelect = ({ transaction, editTransaction }) => {
  const theme = useTheme();

  const { subcategory: transactionSubcategory } = transaction;

  const [categoriesMenuAnchorEl, setCategoriesMenuAnchorEl] = useState(null);

  const onSubcategoryChange = (subcategory) => {
    editTransaction("subcategory")(subcategory);
    setCategoriesMenuAnchorEl(null);
    return editTransaction("tonsCo2e")(0);
  };

  return (
    <>
      <Tooltip title="Click to recategorize this transaction">
        <ButtonBase onClick={(e) => setCategoriesMenuAnchorEl(e.currentTarget)}>
          <Paper elevation={2} style={{ padding: theme.spacing(1) }}>
            <HeaderDisplay transactionSubcategory={transactionSubcategory} />
          </Paper>
        </ButtonBase>
      </Tooltip>
      <InputCategoriesMenu
        categoriesMenuAnchorEl={categoriesMenuAnchorEl}
        setCategoriesMenuAnchorEl={setCategoriesMenuAnchorEl}
        onSubcategoryChange={onSubcategoryChange}
        transactionSubcategory={transactionSubcategory}
      />
    </>
  );
};

const NonManualKnownVendorSpendBasedToggle = ({
  subcategory: transactionSubcategory,
  knownVendor = {},
  editTransaction,
}) => {
  const [vendors] = useCachedDisplayData("vendors");
  const { id: knownVendorId } = knownVendor;

  const { emissionCategory: knownVendorCategory } =
    vendors.find((vendor) => vendor?.id === knownVendorId) || {};

  const {
    name: knownVendorCategoryName,
    icon: knownVendorCategoryIcon,
    naics,
  } = findSubcategory(knownVendorCategory);
  const { code, kgsCo2ePerDollar } = naics || {};
  const { icon: spendBasedIcon } = findSubcategory("spend-based");

  const subcategoryButtonProps =
    transactionSubcategory !== "spend-based"
      ? {
          avatar: (
            <CategoriesAvatar subcategory={knownVendorCategory} size="small" />
          ),
        }
      : { icon: knownVendorCategoryIcon };

  const spendBasedCategoryProps =
    transactionSubcategory === "spend-based"
      ? { avatar: <CategoriesAvatar subcategory="spend-based" size="small" /> }
      : { icon: spendBasedIcon };

  const onToggle = (subcategory) => {
    editTransaction("subcategory")(subcategory);

    if (subcategory === "spend-based") {
      return editTransaction("knownVendor")({
        ...knownVendor,
        naicsCode: code,
        tonsCo2ePerDollar: kgsToTons(kgsCo2ePerDollar),
        scopeThreeCategory: 1,
      });
    }

    return editTransaction("knownVendor")(knownVendor);
  };

  return (
    <ToggleButtons
      value={transactionSubcategory}
      onChange={onToggle}
      buttons={[
        {
          value: knownVendorCategory,
          name: knownVendorCategoryName,
          ...subcategoryButtonProps,
        },
        {
          value: "spend-based",
          name: "Spend Based",
          ...spendBasedCategoryProps,
        },
      ]}
    />
  );
};

const HeaderInput = ({ transaction, editTransaction }) => {
  const { knownVendor, id, subcategory, source } = transaction;
  const { emissionCategory } = knownVendor || {};
  const { naics } = findSubcategory(emissionCategory);

  if (
    !id ||
    (knownVendor &&
      (emissionCategory === "spend-based" ||
        source === "manual-entry" ||
        !naics))
  ) {
    return <HeaderDisplay transactionSubcategory={subcategory} />;
  }

  if (!knownVendor) {
    return (
      <IconSelect transaction={transaction} editTransaction={editTransaction} />
    );
  }

  return (
    <NonManualKnownVendorSpendBasedToggle
      editTransaction={editTransaction}
      {...transaction}
    />
  );
};

const TransactionDetailsBlock = ({
  transaction,
  originalTransaction = {},
  editTransaction,
  saveTransaction,
  setIsSlideOpen,
  onRejectTransaction,
  saveButtonText,
  slideLeftPanel,
}) => {
  const {
    vendor,
    subcategory,
    date,
    employees = [],
    office = {},
    source,
    value,
    knownVendor,
  } = transaction;

  const {
    employees: originalEmployees = [],
    office: originalOffice = {},
    knownVendor: originalKnownVendor = {},
    subcategory: originalSubcategory,
    tonsCo2e: originalTonsCo2e = 0,
  } = originalTransaction;

  const [{ earliestNewEmissionDate }] = useAccountingData();
  const [{ startDate }] = useAccountData();
  const [companyEmployees] = useCachedDisplayData("employees");
  const [companyOffices] = useCachedDisplayData("offices", [
    ["type", "==", "companyOffice"],
  ]);

  const [calcLoading, setCalcLoading] = useState(false);

  const inputHookProps = {
    setCalcLoading,
    transaction,
    onSave: async (updateObj) => {
      const newTransactionId = await saveTransaction({
        archived: false,
        date,
        name: vendor,
        subcategory,
        source,
        status: "confirmed",
        value,
        vendor,
        knownVendor,
        ...buildFormattedTagsObjs(transaction),
        ...updateObj,
      });
      setIsSlideOpen(false);
      return newTransactionId;
    },
  };

  const flightsInputProps = useFlightsInput(inputHookProps);
  const fuelInputProps = useFuelInput(inputHookProps);
  const equipmentFuelInputProps = useEquipmentFuelInput(inputHookProps);
  const rentalCarInputProps = useRentalCarInput({
    ...inputHookProps,
    onRejectTransaction,
  });
  const combinedUtilitiesInputProps = useCombinedUtilitiesInput(inputHookProps);
  const gasInputProps = useGasInput(inputHookProps);
  const electricUtilityInputProps = useElectricityInput(inputHookProps);
  const ridesInputProps = useRidesInput(inputHookProps);
  const shippingInputProps = useShippingInput(inputHookProps);
  const mileageInputProps = useMileageInput(inputHookProps);
  const hotelInputProps = useHotelInput(inputHookProps);
  const wasteInputProps = useWasteInput(inputHookProps);
  const trainInputProps = useTrainInput(inputHookProps);
  const spendBasedInputProps = useSpendBasedInput(inputHookProps);

  const subcategoryInputs = {
    flights: flightsInputProps,
    hotels: hotelInputProps,
    fuel: fuelInputProps,
    "equipment-fuel": equipmentFuelInputProps,
    "rental-cars": rentalCarInputProps,
    utilities: combinedUtilitiesInputProps,
    gas: gasInputProps,
    electricity: electricUtilityInputProps,
    rides: ridesInputProps,
    shipping: shippingInputProps,
    mileage: mileageInputProps,
    waste: wasteInputProps,
    trains: trainInputProps,
    "spend-based": spendBasedInputProps,
  };

  const {
    onTransactionSave,
    inputBlock,
    saveEnabled,
    tonsCo2e: inputTonsCo2e,
  } = subcategoryInputs[subcategory] || {};

  const tonsCo2e =
    subcategory === originalSubcategory && !inputTonsCo2e
      ? originalTonsCo2e
      : inputTonsCo2e;

  const isSaveEnabled = () => {
    if (source === "utilityApi" || tonsCo2e === undefined) {
      return false;
    }

    if (slideLeftPanel) {
      return saveEnabled;
    }

    const { id: originalOfficeId } = originalOffice || {};
    const { id: officeId } = office || {};
    const { id: originalKnownVendorId } = originalKnownVendor || {};
    const { id: knownVendorId } = knownVendor || {};
    const areEmployeesSame = originalEmployees.reduce(
      (acc, { id }) => acc && employees.find((employee) => employee.id === id),
      true
    );

    const tagsChanged =
      originalOfficeId !== officeId ||
      originalKnownVendorId !== knownVendorId ||
      !areEmployeesSame;

    return tagsChanged || saveEnabled;
  };

  const generateMinDate = () => {
    if (earliestNewEmissionDate) {
      return earliestNewEmissionDate;
    }

    const { id: officeId } = office || {};
    const employeesIds = employees.map(({ id: employeeId }) => employeeId);

    if (officeId) {
      const [{ startDate: officeStartDate = startDate }] = companyOffices.length
        ? companyOffices.filter(({ id }) => id === officeId)
        : [{}];

      return officeStartDate;
    }

    if (employeesIds) {
      const employeesStartDates = companyEmployees
        .filter(({ id }) => employeesIds.includes(id))
        .map(({ startDate }) => startDate);

      return employeesStartDates.sort((a, b) => b - a)[0] || startDate;
    }

    return startDate;
  };

  const buildAlert = () => {
    const { emissionCategory, transactions = [], name } = knownVendor || {};
    if (!emissionCategory && transactions.length) {
      return `Tagging this transaction with ${name} will categorize ${
        transactions.length
      } transaction${letterSBoolean(transactions)} as ${subcategory}.`;
    }

    return "";
  };

  return (
    <SlideLayout
      isSlideOpen
      setIsSlideOpen={setIsSlideOpen}
      header={
        <HeaderInput
          transaction={transaction}
          editTransaction={editTransaction}
        />
      }
      tagInputs={
        <Box px={2}>
          <Grid container direction="column" spacing={2}>
            <Grid item container spacing={2}>
              {subcategory !== "flights" && (
                <Grid item xs={5}>
                  <DatePicker
                    label="Emission Date"
                    date={date}
                    editDate={editTransaction("date")}
                    minDate={generateMinDate()}
                    maxDate={subcategory !== "hotels" ? new Date() : undefined}
                  />
                </Grid>
              )}
              <Grid item xs={7}>
                <TextField
                  label="Emission Name"
                  large
                  value={vendor}
                  setValue={editTransaction("vendor")}
                />
              </Grid>
            </Grid>
            <Grid item>
              <TransactionsTagsInputs
                transaction={transaction}
                editTransaction={editTransaction}
              />
            </Grid>
          </Grid>
        </Box>
      }
      content={inputBlock}
      footer={
        <TransactionFooterRow
          saveEnabled={isSaveEnabled()}
          saveTransaction={onTransactionSave}
          tonsCo2e={tonsCo2e}
          acceptText={saveButtonText}
          onRejectTransaction={onRejectTransaction}
          calcLoading={calcLoading}
          source={source}
          alert={buildAlert()}
        />
      }
      leftContent={slideLeftPanel}
    />
  );
};
export default TransactionDetailsBlock;
