import React, { useState, useRef, useEffect, useContext } from "react";

import {
  Box,
  Typography,
  Grid,
  Button,
  useTheme,
  ThemeProvider,
  Container,
} from "@mui/material";

import { DefaultPaper } from "@aclymatepackages/atoms";
import { mergeDarkTheme } from "@aclymatepackages/themes";

import Link from "../atoms/mui/Link";
import LoadingCardLayoutBox from "../layouts/LoadingCardLayoutBox";
import OffsetSurvey from "../inputs/flows/OffsetSurvey";
import ProjectCategoriesBar from "../modules/projects/ProjectCategoriesBar";
import ProjectCategoriesCarousel from "../modules/projects/ProjectCategoriesCarousel";
import ProjectsDisplayBlock from "../modules/projects/ProjectsDisplayBlock";

import { formatOffsetPreferencesForScoring } from "../../helpers/components/offsetSurvey";
import { useLayoutHelpers } from "../../helpers/otherHelpers";
import {
  ProjectsContext,
  ProjectsContextProvider,
} from "../../helpers/contexts/projects";

const displayPageUrl = "/offsets";

const OnboardingProjectsCta = () => (
  <Container>
    <DefaultPaper>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h6" align="center">
            Want to support this project? Start your 33 day free trial.
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" align="center">
            No Credit Card Required. Cancel Any Time.
          </Typography>
        </Grid>
        <Grid item container justifyContent="center">
          <Grid item>
            <Link href="/account-creation/company">
              <Button variant="contained" color="secondary">
                Start Trial
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </DefaultPaper>
  </Container>
);

const PageHeader = ({ headerRef }) => {
  const { isMobile } = useLayoutHelpers();
  const { palette } = useTheme();

  return (
    <Box
      ref={headerRef}
      p={isMobile ? 1 : 2}
      style={{ backgroundColor: palette.primary.main }}
      position="sticky"
      top={0}
      zIndex={100}
    >
      <ThemeProvider theme={mergeDarkTheme}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          wrap="nowrap"
        >
          <Grid item>
            <Typography
              variant={isMobile ? "subtitle2" : "h6"}
              color="textPrimary"
            >
              Start Your Free Trial. Support Projects from the largest selection
              of offsets in the US
            </Typography>
          </Grid>
          <Grid item>
            <Button
              href="/account-creation/company"
              variant="contained"
              color="primary"
              style={{ whiteSpace: "nowrap" }}
            >
              Start Trial
            </Button>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Box>
  );
};

const CarouselWelcomeHeader = ({ projects = [], setShowSurvey }) => {
  const theme = useTheme();

  return (
    <Box
      style={{
        flexGrow: 1,
      }}
      display="flex"
      flexDirection="column"
      py={3}
    >
      <Box
        style={{
          backgroundColor: "white",
          width: "50%",
          minWidth: "300px",
          maxWidth: "550px",
          borderRadius: `0 ${theme.spacing(4)} ${theme.spacing(4)} 0`,
          flexGrow: 1,
        }}
        p={3}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant="h1">CARBON OFFSET PROJECTS</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" gutterBottom color="textSecondary">
              Carbon offsets are your way to invest in meaningful, measurable
              projects that either pull greenhouse gases out of the atmoshpere
              or prevent them from ending up there. Browse through our{" "}
              {projects.length} projects to find the perfect one that your
              customers, employees, and other stakeholders can be proud of.
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Not sure which project is right for you? Take a quick quiz to
              discover the best option for your business?
            </Typography>
          </Grid>
          <Grid item container justifyContent="center">
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setShowSurvey(true)}
              >
                Take Quiz
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const OnboardingOffsetsBlock = ({ showSurvey, setShowSurvey }) => {
  const headerRef = useRef();
  const theme = useTheme();
  const { isMobile } = useLayoutHelpers();
  const { projectsLoading, selectedCategory, splitView } =
    useContext(ProjectsContext);
  const { image } = selectedCategory;

  const [headerHeight, setHeaderHeight] = useState(0);
  const [offsetPreferences, setOffsetPreferences] = useState(null);

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.offsetHeight);
    }
  }, [headerRef, headerHeight]);

  const saveOffsetPreferences = async (preferences) => {
    const formattedOffsetPreferences = await formatOffsetPreferencesForScoring(
      preferences
    );
    return setOffsetPreferences(formattedOffsetPreferences);
  };

  const boxPadding = isMobile ? 1 : 3;

  return (
    <>
      {showSurvey && (
        <OffsetSurvey
          projectsLoading={projectsLoading}
          setShowSurvey={setShowSurvey}
          saveOffsetPreferences={saveOffsetPreferences}
          confirmClose={false}
        />
      )}
      <PageHeader headerRef={headerRef} />
      {!projectsLoading && (
        <Box
          display="flex"
          justifyContent="flex-end"
          flexDirection="column"
          style={{
            minHeight: "60vh",
            backgroundImage: `url('/images/${image}.jpg')`,
            backgroundSize: "cover",
            backgroundPosition: "center bottom 35%",
          }}
          position="relative"
        >
          <ProjectCategoriesCarousel />
        </Box>
      )}
      <Box position="sticky" top={headerHeight - 1} zIndex={100}>
        <ProjectCategoriesBar />
      </Box>
      <LoadingCardLayoutBox
        backgroundColor={theme.palette.secondary.main}
        isLoading={projectsLoading}
        style={{
          padding: `0 ${theme.spacing(boxPadding)} ${theme.spacing(
            boxPadding
          )} ${theme.spacing(boxPadding)}`,
          height:
            splitView === "both"
              ? `calc(100vh - ${60 + headerHeight}px)`
              : "auto",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ProjectsDisplayBlock
          headerHeight={headerHeight}
          ProjectPurchaseFlow={OnboardingProjectsCta}
          preferences={offsetPreferences}
          displayPageUrl={displayPageUrl}
        />
      </LoadingCardLayoutBox>
    </>
  );
};

const OnboardingOffsets = () => {
  const [showSurvey, setShowSurvey] = useState(false);

  return (
    <ProjectsContextProvider
      GlobalHeaderComponent={CarouselWelcomeHeader}
      globalHeaderProps={{ setShowSurvey }}
      displayPageUrl="/offsets"
    >
      <OnboardingOffsetsBlock
        showSurvey={showSurvey}
        setShowSurvey={setShowSurvey}
      />
    </ProjectsContextProvider>
  );
};

export default OnboardingOffsets;
