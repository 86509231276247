import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import * as Sentry from "@sentry/react";

import {
  Chip,
  FormControl,
  InputLabel,
  OutlinedInput,
  useTheme,
  FormHelperText,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faPlane,
  faBus,
  faCar,
  faShuttleVan,
  faHotel,
  faCampground,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { faRv, faHouse } from "@fortawesome/pro-solid-svg-icons";

import { useLayoutHelpers, mainTheme } from "@aclymatepackages/themes";
import { emailRegExpTest, numbersRegExpTest } from "@aclymatepackages/reg-exp";
import {
  editObjectData,
  mergeObjectData,
} from "@aclymatepackages/array-immutability-helpers";
import { Select, TextField } from "@aclymatepackages/atoms";
import { tonsToLbs } from "@aclymatepackages/converters";
import { DefaultPaper } from "@aclymatepackages/atoms";
import {
  useSharedFormLoading,
  MultiPartFormLayout,
} from "@aclymatepackages/multi-part-form";
import { YesNoQuestion } from "@aclymatepackages/modules";
import { isObjectEmpty } from "@aclymatepackages/other-helpers";

import PlacesAutocomplete from "../inputs/autocomplete/PlacesAutocomplete";
import ButtonCards from "../atoms/buttons/ButtonCards";

import { setAddress } from "../../helpers/utils/geography";

import { fetchOurApi } from "../../helpers/utils/apiCalls";
import {
  buildTravelEmissionsSurveyRows,
  editGroupAddress,
  calcAvoidedEmissions,
  calcTravelEmissions,
  calcLodgingEmissions,
} from "../../helpers/components/events";
import { analyticsTrack } from "../../helpers/analytics";

const TravelMemberDisplayBlock = ({
  type,
  listItems = [],
  confirmBlock,
  setConfirmBlock,
}) => {
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <DefaultPaper style={{ backgroundColor: "rgba(255,255,255,0.55)" }}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <List>
                {listItems.map(({ icon, text }, idx) => (
                  <ListItem key={`travel-list-item-${idx}`}>
                    <ListItemIcon>
                      <FontAwesomeIcon icon={icon} />
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </DefaultPaper>
      </Grid>
      <Grid item>
        <YesNoQuestion
          question={`Is this ${type} information correct?`}
          setValue={(value) => setConfirmBlock(value)}
          value={confirmBlock}
          lightBackground
        />
      </Grid>
    </Grid>
  );
};

const CollectMultipleEmails = ({
  label,
  emailsArray,
  setEmailsArray,
  currentEmail: parentCurrentEmail,
  setCurrentEmail: setParentCurrentEmail,
}) => {
  const [localCurrentEmail, setLocalCurrentEmail] = useState("");

  const currentEmail = parentCurrentEmail || localCurrentEmail;
  const setCurrentEmail = setParentCurrentEmail || setLocalCurrentEmail;

  const onEmailKeyUp = ({ key, target: { value } }) => {
    const keyIsNextKey = [" ", "Enter", ","].includes(key);

    if (!value && key === "Backspace") {
      const newEmails = emailsArray.filter(
        (_, idx) => idx !== emailsArray.length - 1
      );
      return setEmailsArray(newEmails);
    }

    if (keyIsNextKey) {
      setCurrentEmail("");
    }

    const adjustedValue =
      key === "," || key === " " ? value.substring(0, value.length - 1) : value;
    if (adjustedValue && keyIsNextKey) {
      const newEmails = [...emailsArray, adjustedValue];
      return setEmailsArray(newEmails);
    }
    return null;
  };

  const onDeleteUserEmail = (email) => {
    const newEmails = emailsArray.filter(
      (emailStored) => email !== emailStored
    );
    return setEmailsArray(newEmails);
  };

  const InputEmailChip = ({ email }) => {
    const { palette } = useTheme();
    const isValidEmail = emailRegExpTest(email);
    const styleObj = isValidEmail
      ? { borderColor: palette.success.dark }
      : { backgroundColor: palette.error.main, color: "white" };

    return (
      <Chip
        variant={isValidEmail ? "outlined" : "default"}
        style={{
          margin: "2px",
          ...styleObj,
        }}
        label={email}
        onDelete={() => onDeleteUserEmail(email)}
        size="small"
      />
    );
  };

  return (
    <FormControl
      variant="outlined"
      fullWidth
      focused={!!emailsArray.length}
      style={{ display: "inline-flex", flexWrap: "wrap", flex: 1 }}
      size="small"
    >
      <InputLabel style={{ height: "22px" }}>{label}</InputLabel>
      <OutlinedInput
        label={label}
        style={{
          paddingTop: emailsArray.length > 2 ? "8px" : 0,
          display: "inline-flex",
          flexWrap: "wrap",
          flex: 1,
        }}
        value={currentEmail}
        onChange={(e) => setCurrentEmail(e.target.value)}
        onKeyUp={onEmailKeyUp}
        startAdornment={emailsArray.map((email, idx) => (
          <InputEmailChip email={email} key={`new-user-email-${idx}`} />
        ))}
        onBlur={() => {
          if (!!currentEmail) {
            const newEmails = [...emailsArray, currentEmail];
            return setEmailsArray(newEmails);
          }
          return setCurrentEmail("");
        }}
      />
      <FormHelperText>
        Please enter the email addresses of the people you're traveling with so
        we can prefill their survey when they fill out.
      </FormHelperText>
    </FormControl>
  );
};

const TravelGroupEmailInput = ({
  emailsArray,
  setEmailsArray,
  currentEmail,
  setCurrentEmail,
  onNextStep,
}) => (
  <Grid container direction="column" spacing={2}>
    <Grid item>
      <CollectMultipleEmails
        label="Who are you traveling with?"
        emailsArray={emailsArray}
        setEmailsArray={setEmailsArray}
        currentEmail={currentEmail}
        setCurrentEmail={setCurrentEmail}
      />
    </Grid>
    <Grid item container justifyContent="center">
      <Grid item>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={mainTheme}>
            <Button variant="contained" color="primary" onClick={onNextStep}>
              {!emailRegExpTest(currentEmail) && !emailsArray.length
                ? "skip"
                : "Next Step"}
            </Button>
          </ThemeProvider>
        </StyledEngineProvider>
      </Grid>
    </Grid>
  </Grid>
);

const useTravelGroupEmailInput = (setPeopleWhoTravelTogetherEmails) => {
  const [emailsArray, setEmailsArray] = useState([]);
  const [currentEmail, setCurrentEmail] = useState("");

  const onNextStep = () => {
    if (emailRegExpTest(currentEmail)) {
      return setPeopleWhoTravelTogetherEmails([...emailsArray, currentEmail]);
    }

    return setPeopleWhoTravelTogetherEmails(emailsArray);
  };

  return {
    NextStepInput: TravelGroupEmailInput,
    nextStepInputProps: {
      emailsArray,
      setEmailsArray,
      currentEmail,
      setCurrentEmail,
      onInputSelect: onNextStep,
      inputSelectPropName: "onNextStep",
    },
  };
};

const useNewAttendeePersonalInfoFormRows = ({
  eventData,
  newAttendee,
  editNewAttendee,
}) => {
  const { name, address, ticketType = {}, numberDaysAttended } = newAttendee;
  const { isTicketValidForEntireEvent } = ticketType;
  const { description } = address || {};

  const { ticketTypes = [] } = eventData || {};

  const onTicketSelect = (ticket) => {
    const { ticketLodgingType } = ticket;
    editNewAttendee("ticketLodgingCategory")(ticketLodgingType);
    return editNewAttendee("ticketType")(ticket);
  };

  const ticketSelectQuestion = ticketTypes.length
    ? [
        {
          NextStepInput: Select,
          nextStepInputProps: {
            size: "small",
            label: "Select your ticket type",
            labelWidth: 160,
            value: newAttendee.ticketType || "",
            onInputSelect: onTicketSelect,
            inputSelectPropName: "editValue",
            options: ticketTypes.map((ticket) => ({
              label: ticket.ticketName,
              value: ticket,
            })),
          },
          value: newAttendee.ticketType,
        },
      ]
    : [];

  const numberOfDaysQuestion = !isTicketValidForEntireEvent
    ? [
        {
          label: "How many days will you be attending the event?",
          value: numberDaysAttended,
          editData: editNewAttendee("numberDaysAttended"),
          error: !numbersRegExpTest(numberDaysAttended),
        },
      ]
    : [];

  const personalInfoRows = [
    {
      label: "What's your first and last name?",
      value: name,
      editData: editNewAttendee("name"),
    },
    {
      NextStepInput: PlacesAutocomplete,
      nextStepInputProps: {
        variant: "standard",
        label: "What's  your address?",
        size: "small",
        place: description || "",
        onInputSelect: editGroupAddress(editNewAttendee, eventData),
        inputSelectPropName: "editPlace",
        helperText: "So we know where you're traveling from",
      },
      value: description,
    },
    ...ticketSelectQuestion,
    ...numberOfDaysQuestion,
  ];

  return personalInfoRows.map((row) => ({ ...row, form: "personalInfo" }));
};

const useNewAttendeeLodgingFormRows = ({
  newAttendee,
  editNewAttendee,
  mileageDistance,
}) => {
  const { theme } = useLayoutHelpers();

  const {
    isNotStayingHome,
    lodgingCategory,
    lodgingAddress,
    hotelNumberOfRooms,
    lodgingGroupSize,
    ticketLodgingCategory,
    travelGroupSize,
  } = newAttendee;

  if (ticketLodgingCategory) {
    return [];
  }

  const generateCommonQuestions = () => {
    const hotelSelectionQuestion =
      lodgingCategory === "hotel"
        ? [
            {
              NextStepInput: PlacesAutocomplete,
              nextStepInputProps: {
                variant: "standard",
                label: "What is the name of your hotel?",
                size: "small",
                place: lodgingAddress || "",
                onInputSelect: setAddress(editNewAttendee("lodgingAddress")),
                inputSelectPropName: "editPlace",
              },
              value: lodgingAddress,
            },
          ]
        : [];

    const hotelNumberOfNightsQuestion =
      lodgingCategory === "hotel" && travelGroupSize > 1
        ? [
            {
              label: "How many rooms is your group staying in?",
              value: hotelNumberOfRooms,
              editData: editNewAttendee("hotelNumberOfRooms"),
              error: !numbersRegExpTest(hotelNumberOfRooms),
            },
          ]
        : lodgingCategory === "property"
        ? [
            {
              label: "How many people are staying here?",
              value: lodgingGroupSize,
              editData: editNewAttendee("lodgingGroupSize"),
              error: !numbersRegExpTest(lodgingGroupSize),
            },
          ]
        : [];

    return [
      {
        NextStepInput: ButtonCards,
        nextStepInputProps: {
          question: "Where will you be staying during the event?",
          color: theme.palette.secondary.main,
          selectedOption: lodgingCategory || "",
          onInputSelect: editNewAttendee("lodgingCategory"),
          inputSelectPropName: "setOption",
          options: [
            { title: "Hotel", value: "hotel", fontAwesomeIcon: faHotel },
            {
              title: "Rental",
              value: "property",
              fontAwesomeIcon: faHouse,
            },
            {
              title: "Tent Camping",
              value: "tent",
              fontAwesomeIcon: faCampground,
            }, //These are commented out because most survey that aren't music festivals don't have them.
            // { title: "Rv Camping", value: "rv", fontAwesomeIcon: faRv },
            {
              title: "With Friends",
              value: "friends",
              fontAwesomeIcon: faUsers,
            },
          ],
        },
        value: lodgingCategory,
      },
      ...hotelSelectionQuestion,
      ...hotelNumberOfNightsQuestion,
    ];
  };

  const generateLongerLodgingOrAvoidedEmissionQuestions = () => {
    const commonQuestionsOrAvoidedEmissionQuestion = isNotStayingHome
      ? generateCommonQuestions()
      : [];

    return [
      {
        NextStepInput: YesNoQuestion,
        nextStepInputProps: {
          question:
            "Are you staying somewhere other than your home for this event?",
          value: isNotStayingHome,
          onInputSelect: editNewAttendee("isNotStayingHome"),
          inputSelectPropName: "setValue",
          lightBackground: true,
        },
        value: isNotStayingHome || isNotStayingHome === false,
      },
      ...commonQuestionsOrAvoidedEmissionQuestion,
    ];
  };

  const lodgingQuestions =
    mileageDistance < 50
      ? generateLongerLodgingOrAvoidedEmissionQuestions()
      : generateCommonQuestions();

  return lodgingQuestions.map((row) => ({ ...row, form: "lodging" }));
};

const useNewAttendeeAvoidedEmissionForm = ({
  newAttendee,
  editNewAttendee,
}) => {
  const { theme } = useLayoutHelpers();

  const {
    isThermostatSetOver7Degree,
    avgDailyDrivingMiles,
    dailyDrivingMileage,
    isNotStayingHome = true,
  } = newAttendee;

  if (!isNotStayingHome) {
    return [];
  }

  const thermostatQuestion = {
    NextStepInput: YesNoQuestion,
    nextStepInputProps: {
      question:
        "While you're at the event, did/will you set your thermostat back by over 7 degrees to reduce your energy usage while you're gone?",
      value: isThermostatSetOver7Degree,
      onInputSelect: editNewAttendee("isThermostatSetOver7Degree"),
      inputSelectPropName: "setValue",
      lightBackground: true,
    },
    value: isThermostatSetOver7Degree || isThermostatSetOver7Degree === false,
  };

  const buildOptionSubtitle = (miles) => `About ${Math.round(miles)} miles/day`;

  const commuteQuestion = {
    NextStepInput: ButtonCards,
    nextStepInputProps: {
      question: "How many miles do you drive on a typical day?",
      color: theme.palette.secondary.main,
      selectedOption: dailyDrivingMileage || "",
      onInputSelect: editNewAttendee("dailyDrivingMileage"),
      inputSelectPropName: "setOption",
      options: [
        {
          title: "Very Little",
          value: avgDailyDrivingMiles / 4,
          subtitle: buildOptionSubtitle(avgDailyDrivingMiles / 4),
        },
        {
          title: "Below Average",
          value: avgDailyDrivingMiles * 0.75,
          subtitle: buildOptionSubtitle(avgDailyDrivingMiles * 0.75),
        },
        {
          title: "An Average Amount",
          value: avgDailyDrivingMiles,
          subtitle: buildOptionSubtitle(avgDailyDrivingMiles),
        },
        {
          title: "Above Average",
          value: avgDailyDrivingMiles * 1.25,
          subtitle: buildOptionSubtitle(avgDailyDrivingMiles * 1.25),
        },
        {
          title: "A lot",
          value: avgDailyDrivingMiles * 2,
          subtitle: buildOptionSubtitle(avgDailyDrivingMiles * 2),
        },
      ],
    },
    value: dailyDrivingMileage,
  };

  const avoidedEmissionsQuestions = [thermostatQuestion, commuteQuestion];

  return avoidedEmissionsQuestions.map((row) => ({
    ...row,
    form: "avoidedEmissions",
  }));
};

const useGroupMemberTravelPart = ({
  confirmTravelPart,
  setConfirmTravelPart,
  survey,
  travelGroup,
  eventData,
}) => {
  const { surveyFillerName } = travelGroup || {};
  const { travel } = survey || {};
  const {
    type,
    toAirport,
    fromAirport,
    fromAirportToEventVehicleType,
    publicTransitType,
    carpoolCarType,
    vehicleType,
  } = travel || {};

  const { airportName: fromAirportName } = fromAirport || {};
  const { airportName: toAirportName } = toAirport || {};
  const { name: eventName } = eventData;

  const listItems = {
    flying: [
      {
        icon: faPlane,
        text: `You are flying to ${eventName} 
      from ${fromAirportName} to ${toAirportName}`,
      },
      {
        icon: fromAirportToEventVehicleType !== "publicTransit" ? faCar : faBus,
        text: `Then you're taking a ${fromAirportToEventVehicleType} from ${toAirportName} to ${eventName}`,
      },
    ],
    personalCar: [
      {
        icon: faCar,
        text: `You are riding in ${surveyFillerName}'s ${vehicleType} to ${eventName}`,
      },
    ],
    publicTransit: [
      {
        icon: faBus,
        text: `You are taking a ${publicTransitType} to ${eventName}`,
      },
    ],
    carpool: [
      {
        icon: faShuttleVan,
        text: `You are carpooling to ${eventName} in ${surveyFillerName}'s ${carpoolCarType}`,
      },
    ],
  };

  return (
    <TravelMemberDisplayBlock
      type="travel"
      listItems={listItems[type]}
      confirmBlock={confirmTravelPart}
      setConfirmBlock={setConfirmTravelPart}
    />
  );
};

const useGroupMemberLodgingPart = ({
  confirmLodgingPart,
  setConfirmLodgingPart,
  survey,
  eventData,
}) => {
  const { lodging } = survey || {};
  const { name: eventName } = eventData;

  const { type } = lodging || {};

  const listItems = {
    hotel: {
      icon: faHotel,
      textAid: "staying at a ",
    },
    property: {
      icon: faHouse,
      textAid: "staying at a rental ",
    },
    tent: { icon: faCampground, textAid: "camping in a " },
    rv: { icon: faRv, textAid: "camping in an " },
    friends: { icon: faUsers, textAid: "staying with " },
  };

  const { icon, textAid = "" } = listItems[type] || {};

  return (
    <TravelMemberDisplayBlock
      type="lodging"
      listItems={[
        {
          icon,
          text: `You will be ${textAid}${type} during ${eventName}`,
        },
      ]}
      confirmBlock={confirmLodgingPart}
      setConfirmBlock={setConfirmLodgingPart}
    />
  );
};

const EventAttendeeSurvey = ({ eventData, serverData, setServerData }) => {
  //eslint-disable-next-line
  const [_, setCookie] = useCookies();
  const { theme } = useLayoutHelpers();
  const { setFormLoading } = useSharedFormLoading();

  const [newAttendee, setNewAttendee] = useState({});
  const [confirmTravelPart, setConfirmTravelPart] = useState(undefined);
  const [confirmLodgingPart, setConfirmLodgingPart] = useState(undefined);

  const { travelGroup, survey: existingSurvey } = serverData || {};
  const { surveyFillerName } = travelGroup || {};

  const {
    slug: eventSlug,
    name: eventName,
    companyName,
    companyId,
    id: eventId,
    branding,
    hideGroupQuestions = true,
  } = eventData || {};
  const { secondaryColor } = branding || {};

  const {
    name,
    email,
    address,
    mileageDistance,
    travelGroupSize,
    lodgingGroupSize,
    hotelNumberOfRooms = 1,
    peopleTravelTogetherEmails = [],
    ticketType,
    isEmailOptIn,
  } = newAttendee;
  const { ticketName } = ticketType || {};

  useEffect(() => {
    Sentry.configureScope((scope) => scope.clear());
    Sentry.setContext("Event Individual", { ...newAttendee });
  }, [newAttendee]);

  const editNewAttendee = (field) => (value) =>
    editObjectData(setNewAttendee, field, value);

  const onEmissionsFormSubmit = async () => {
    setFormLoading(true);

    const buildTravelEmissions = async () => {
      if (!travelGroup) {
        const travelData = await calcTravelEmissions({
          newAttendee,
          eventData,
        });

        const groupSize =
          Number(travelGroupSize) >= peopleTravelTogetherEmails.length
            ? Number(travelGroupSize)
            : peopleTravelTogetherEmails.length;

        return { ...travelData, groupSize };
      }

      const { travel } = existingSurvey;

      if (!confirmTravelPart) {
        const { groupSize } = travel;
        const newTravel = await calcTravelEmissions({ newAttendee, eventData });
        return { ...newTravel, groupSize };
      }

      return travel;
    };

    const buildLodgingEmissions = async () => {
      if (!travelGroup || !confirmLodgingPart) {
        return await calcLodgingEmissions({ newAttendee, eventData });
      }

      const { lodging } = existingSurvey;
      return lodging;
    };

    const [travel, lodging, avoidedEmissions] = await Promise.all([
      buildTravelEmissions(),
      buildLodgingEmissions(),
      calcAvoidedEmissions({ newAttendee, eventData }),
    ]);

    const { totalTonsCo2e: lodgingTons = 0 } = lodging;
    const { totalTonsCo2e: travelTons = 0 } = travel;
    const { totalTonsCo2e: avoidedEmissionsTons = 0 } = avoidedEmissions;

    const totalEventTonsCo2e = lodgingTons + travelTons - avoidedEmissionsTons;

    const individual = { name, email, address, isEmailOptIn };
    const surveyData = {
      avoidedEmissions,
      lodging: {
        ...lodging,
        groupSize: Number(lodgingGroupSize),
        hotelNumberOfRooms: Number(hotelNumberOfRooms),
      },
      travel,
      totalEventTonsCo2e,
      eventId,
      name: eventName,
      organizer: { id: companyId, name: companyName },
      slug: eventSlug,
      confirmed: true,
      ticketType: ticketName,
    };

    const { individualSlug, stripeCustomerId } = await fetchOurApi({
      path: "/individuals/event-survey-submit",
      data: { individual, surveyData, peopleTravelTogetherEmails },
      method: "POST",
      callback: (res) => res,
    });

    if (!individualSlug) {
      Sentry.setContext("Event Individual", {
        event: "survey-submit",
        individual,
        surveyData,
      });
      return Sentry.captureException();
    }

    if (isEmailOptIn) {
      analyticsTrack("Event Survey Submit", {
        eventName,
        name,
        email,
        eventFootprintLbs: tonsToLbs(totalEventTonsCo2e),
      });
    }

    setCookie("aclymate-in-slug", individualSlug);

    mergeObjectData(setServerData, {
      attendee: { ...individual, slug: individualSlug, stripeCustomerId },
      survey: surveyData,
    });
    return setFormLoading(false);
  };

  const personalInfoForm = useNewAttendeePersonalInfoFormRows({
    eventData,
    newAttendee,
    editNewAttendee,
  });

  const newAttendeeAvoidedEmissionForm = useNewAttendeeAvoidedEmissionForm({
    newAttendee,
    editNewAttendee,
  });

  const travelGroupEmailInputObj = useTravelGroupEmailInput((emails) =>
    editNewAttendee("peopleTravelTogetherEmails")(emails)
  );
  const newAttendeeTravelForm = buildTravelEmissionsSurveyRows({
    travelGroup: newAttendee,
    editTravelGroup: editNewAttendee,
    eventData,
    form: "travel",
    travelGroupEmailInputObj,
    hideGroupQuestions: hideGroupQuestions || !!existingSurvey,
  });

  const newAttendeeLodgingForm = useNewAttendeeLodgingFormRows({
    newAttendee,
    editNewAttendee,
    individualEvent: eventData,
    mileageDistance,
    isGroupMember: !!travelGroup,
  });

  const groupMemberTravelPart = useGroupMemberTravelPart({
    confirmTravelPart,
    setConfirmTravelPart,
    travelGroup,
    survey: existingSurvey,
    eventData,
  });

  const groupMemberLodgingPart = useGroupMemberLodgingPart({
    confirmLodgingPart,
    setConfirmLodgingPart,
    eventData,
    survey: existingSurvey,
  });

  const generateTravelMemberFormsDependingOnPath = () => {
    const newLodgingFormOrAvoidedEmission = confirmLodgingPart
      ? []
      : [
          {
            name: "lodging",
            label: "Lodging Information",
            title: "Next, we need some information about your accommodations",
          },
        ];

    const afterTravelSurvey = [
      {
        name: "lodging-member",
        label: "Confirm Lodging",
        title: `This is the lodging information that ${surveyFillerName} filled out for you.`,
      },
      ...newLodgingFormOrAvoidedEmission,
    ];

    const confirmLodgingFormOrNewTravelForm = confirmTravelPart
      ? afterTravelSurvey
      : [
          {
            name: "travel",
            label: "Travel Information",
            title: "We now need some details regarding your travel.",
          },
          ...afterTravelSurvey,
        ];

    return [...confirmLodgingFormOrNewTravelForm];
  };

  const newAttendeeSurveyOrTravelMemberSurvey = !travelGroup
    ? [
        {
          name: "travel",
          label: "Travel Information",
        },
        {
          name: "lodging",
          label: "Lodging Information",
        },
      ]
    : [
        {
          name: "travel-member",
          label: "Confirm Travel",
          title: `This is the travel information that ${surveyFillerName} filled out for you.`,
        },
        ...generateTravelMemberFormsDependingOnPath(),
      ];

  const newAttendeeSurveyForms = [
    {
      name: "email",
      label: "Email",
      title: "Let's start with your email address.",
    },
    {
      name: "personalInfo",
      label: "Personal Information",
      title: !travelGroup
        ? "Please tell us a little more about yourself"
        : `Hey ${email}, ${surveyFillerName} already filled out the survey for you. However, we'd love for you to confirm the information that they entered.`,
    },
    ...newAttendeeSurveyOrTravelMemberSurvey,
    {
      name: "avoidedEmissions",
      label: "Avoided Emission",
    },
  ];

  const onEmailInput = async () => {
    const attendeeData = await fetchOurApi({
      path: `/events/attendee/${email}/${eventSlug}`,
      method: "GET",
      callback: (res) => res,
    });

    if (isObjectEmpty(attendeeData)) {
      analyticsTrack("Event Survey Started", { email });
      return null;
    }
    setCookie("aclymate-in-slug", attendeeData.attendee.slug);

    return setServerData({ ...serverData, ...attendeeData });
  };

  const newLodgingPart = confirmLodgingPart ? [] : newAttendeeLodgingForm;

  const newTravelPartOrLodgingConfirmation = confirmTravelPart
    ? [
        {
          input: groupMemberLodgingPart,
          form: "lodging-member",
          value: confirmLodgingPart || confirmLodgingPart === false,
        },
        ...newLodgingPart,
      ]
    : [
        ...newAttendeeTravelForm,
        {
          input: groupMemberLodgingPart,
          form: "lodging-member",
          value: confirmLodgingPart || confirmLodgingPart === false,
        },
        ...newLodgingPart,
      ];

  const newSurveyOrTravelMemberSurvey = !travelGroup
    ? [...newAttendeeTravelForm, ...newAttendeeLodgingForm]
    : [
        {
          input: groupMemberTravelPart,
          form: "travel-member",
          value: confirmTravelPart || confirmTravelPart === false,
        },
        ...newTravelPartOrLodgingConfirmation,
      ];

  const newAttendeeSurveyRows = [
    {
      input: (
        <TextField
          label="What's your email address"
          setValue={editNewAttendee("email")}
          value={email || ""}
        />
      ),
      onNextStep: onEmailInput,
      value: email ? emailRegExpTest(email) : false,
      form: "email",
    },
    ...personalInfoForm,
    ...newSurveyOrTravelMemberSurvey,
    ...newAttendeeAvoidedEmissionForm,
    {
      NextStepInput: YesNoQuestion,
      nextStepInputProps: {
        question:
          "I agree to receive emails from Aclymate including sustainability tips, offset purchase reminders and invitations to other footprint surveys.",
        value: isEmailOptIn,
        onInputSelect: editNewAttendee("isEmailOptIn"),
        inputSelectPropName: "setValue",
        lightBackground: true,
      },
      value: isEmailOptIn || isEmailOptIn === false,
      form: "avoidedEmissions",
    },
  ];

  return (
    <MultiPartFormLayout
      color={secondaryColor || theme.palette.secondary.main}
      title="Calculate your travel footprint to learn about your climate impact."
      subtitle={`If you choose, you can offset it to help ${companyName} achieve their sustainability goals`}
      onFormSubmit={onEmissionsFormSubmit}
      forms={newAttendeeSurveyForms}
      rows={newAttendeeSurveyRows}
      submitLoadingText="Please wait..."
      formSubmitText="See My Results"
      theme={mainTheme}
    />
  );
};

export default EventAttendeeSurvey;
