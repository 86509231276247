import React, { useState, useEffect } from "react";
import { useDrop } from "react-dnd";

import { useTheme } from "@mui/styles";
import {
  Box,
  Grid,
  Button,
  Typography,
  IconButton,
  Tooltip,
  Avatar,
  Paper,
  ButtonBase,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortCircle } from "@fortawesome/pro-solid-svg-icons";

import { TextField, ToggleButtons, Popper } from "@aclymatepackages/atoms";
import { hexToRgba } from "@aclymatepackages/converters";
import { editObjectData } from "@aclymatepackages/array-immutability-helpers";

import PlacesAutocomplete from "../autocomplete/PlacesAutocomplete";
import DbAutocomplete from "../autocomplete/DbAutocomplete";

import {
  useEmployeesApiDbData,
  useSlidableDaysPerWkBlock,
  useWorkDayHeightPx,
} from "../../../helpers/components/inputs";
import {
  useOfficeTypes,
  setOfficeAddress,
} from "../../../helpers/components/offices";
import {
  useVehicleTypeProperties,
  alternateVehicles,
} from "../../../helpers/components/vehicles";
import {
  generateTempId,
  useLayoutHelpers,
} from "../../../helpers/otherHelpers";

const itemTypes = { VEHICLE: "vehicle" };

const WorkspaceScheduleBlock = ({
  type,
  daysPerWk,
  color,
  children,
  ...otherProps
}) => {
  const { color: officeColor } = useOfficeTypes({ type });
  const workDayHeightPx = useWorkDayHeightPx();

  return (
    <Box
      p={0.5}
      style={{
        backgroundColor: color || officeColor,
        color: "white",
        borderRadius: "5px",
        border: `thin solid white`,
        boxSizing: "border-box",
        width: "100%",
        height: `${daysPerWk * workDayHeightPx}px`,
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="relative"
      {...otherProps}
    >
      {children}
    </Box>
  );
};

const WorkspaceScheduleColumn = ({
  weekendDays,
  children,
  editMode,
  ...otherProps
}) => {
  const { palette } = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
      style={{ height: "100%" }}
      {...otherProps}
    >
      <WorkspaceScheduleBlock
        color={palette.travel.main}
        daysPerWk={weekendDays}
      >
        <Typography variant="h6" align="center">
          {weekendDays === 7 ? "On leave" : "Weekend"}
        </Typography>
      </WorkspaceScheduleBlock>
      {children}
    </Box>
  );
};

const editOfficeDaysPerWeek =
  (commuteEndpoints, editCommuteEndpoints) => (editingOfficesArray) => {
    const [
      { daysPerWk: newSelectedDaysPerWk },
      { daysPerWk: newAdjacentDaysPerWk },
    ] = editingOfficesArray;

    if (newSelectedDaysPerWk <= 0 || newAdjacentDaysPerWk <= 0) {
      return;
    }

    return editCommuteEndpoints(
      commuteEndpoints.map((endpoint) => {
        const editingOffice = editingOfficesArray.find(
          ({ tempId }) => tempId === endpoint.tempId
        );

        if (!editingOffice) {
          return endpoint;
        }

        const { daysPerWk: newDaysPerWk } = editingOffice;

        const newTotalWorkingDaysInWeek = commuteEndpoints.reduce(
          (sum, { tempId: endpointId, daysPerWk: oldDaysPerWk }) =>
            endpointId === editingOffice.tempId
              ? sum + newDaysPerWk
              : sum + oldDaysPerWk,
          0
        );

        if (
          newTotalWorkingDaysInWeek > 6.75 ||
          newTotalWorkingDaysInWeek <= 0.25
        ) {
          return endpoint;
        }

        const { daysPerWk: oldDaysPerWk, vehicles: oldVehicles } = endpoint;

        if (oldVehicles && oldVehicles.length) {
          const adjustedDaysPerWk = newDaysPerWk - oldDaysPerWk || 0;
          const largestDaysPerWkVehicle = oldVehicles.reduce((max, vehicle) =>
            vehicle.daysPerWk > max.daysPerWk ? vehicle : max
          );
          const newVehiclesCorrectDaysPerWk = oldVehicles.map((vehicle) =>
            vehicle.id === largestDaysPerWkVehicle.id
              ? { ...vehicle, daysPerWk: vehicle.daysPerWk + adjustedDaysPerWk }
              : vehicle
          );
          const filterVehiclesWithLessThanQuarterDay =
            newVehiclesCorrectDaysPerWk.filter(
              ({ daysPerWk }) => daysPerWk >= 0.25
            );

          return {
            ...endpoint,
            ...editingOffice,
            vehicles: filterVehiclesWithLessThanQuarterDay,
          };
        }

        return { ...endpoint, ...editingOffice };
      })
    );
  };

const CommuteVehicleInputBox = ({
  children,
  styleObj,
  refPropObj,
  ...otherProps
}) => {
  const theme = useTheme();

  return (
    <Box
      {...refPropObj}
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.7)",
        borderRadius: "4px",
        boxSizing: "border-box",
        width: "100%",
        height: "100%",
        padding: theme.spacing(0, 2),
        ...styleObj,
      }}
      {...otherProps}
    >
      {children}
    </Box>
  );
};

//TODO: -CLEANUP- There really needs to be a reusable loosely coupled popper component that's not FilterPopper
const EditAddWorkspacePopper = ({
  anchorEl,
  setAnchorEl,
  type,
  id,
  commuteEndpoints,
  commuteHome = {},
  name: existingName,
  address: existingAddress,
  open,
  companyOffices = [],
  onPopperClick,
  editCommuteScheduleHome,
  updateCommuteEndpointInfo,
  createNewCommuteEndpoint,
}) => {
  const { isMobile } = useLayoutHelpers();

  const openOffices = companyOffices.filter(({ changes }) =>
    changes ? !changes.find(({ status }) => status === "closed") : true
  );

  const otherCommuteEndpoints = commuteEndpoints.filter(
    ({ id: endpointId }) => endpointId !== id
  );
  const availableOffices = openOffices.filter(
    ({ id: officeId }) =>
      !otherCommuteEndpoints.some(
        ({ id: endpointOfficeId }) => endpointOfficeId === officeId
      )
  );

  const [newWorkspace, setNewWorkspace] = useState({
    name: existingName,
    address: existingAddress,
    ...commuteHome,
  });
  const [workspaceType, setWorkspaceType] = useState(type);

  const {
    homeArea,
    homeOfficeArea,
    electricRenewablesPercentage = 0,
    name,
    address,
  } = newWorkspace || {};

  const toggleButtons =
    commuteEndpoints.find(({ type }) => type === "homeOffice") &&
    type !== "homeOffice"
      ? [
          { value: "companyOffice", name: "Company Office" },
          { value: "coffeeShop", name: "Coffee Shop" },
          { value: "coworking", name: "Coworking Space" },
        ]
      : [
          { value: "companyOffice", name: "Company Office" },
          { value: "homeOffice", name: "Home Office" },
          { value: "coffeeShop", name: "Coffee Shop" },
          { value: "coworking", name: "Coworking Space" },
        ];

  const editNewWorkspaceField = (field) => (value) =>
    editObjectData(setNewWorkspace, field, value);

  //TODO: these db autocompletes should be just regular autocompletes from @aclymatepackages/atoms since they aren't pulling from the db
  const findInputType = () => {
    switch (workspaceType) {
      case "companyOffice":
        return (
          <DbAutocomplete
            label="Select a company office"
            availableOptions={availableOffices}
            value={newWorkspace}
            setValue={setNewWorkspace}
            size="small"
          />
        );
      case "homeOffice":
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Home square footage"
                value={homeArea}
                setValue={editNewWorkspaceField("homeArea")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Home office square footage"
                value={homeOfficeArea}
                setValue={editNewWorkspaceField("homeOfficeArea")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Home office electric renewables percentage"
                value={electricRenewablesPercentage}
                setValue={editNewWorkspaceField("electricRenewablesPercentage")}
              />
            </Grid>
            <Grid item xs={12}>
              <PlacesAutocomplete
                label="What is the home address of this employee?"
                place={address}
                editPlace={setOfficeAddress(editNewWorkspaceField)}
                size="small"
              />
            </Grid>
          </Grid>
        );
      default:
        return (
          <PlacesAutocomplete
            label={`${
              type === "coffeeShop" ? "Coffee Shop" : "Coworking space"
            } address`}
            place={address}
            editPlace={setOfficeAddress(editNewWorkspaceField, true)}
            size="small"
          />
        );
    }
  };

  const onButtonClick = (saveFunction) => () => {
    if (workspaceType === "homeOffice") {
      editCommuteScheduleHome(newWorkspace);
    }

    saveFunction({
      type: workspaceType,
      ...newWorkspace,
    });
    return onPopperClick();
  };

  const findShowNewOffice = () => {
    if (type === "homeOffice") {
      return workspaceType !== "homeOffice";
    }

    if (type === "companyOffice") {
      const isOfficeOpen = !!availableOffices.find(
        (office) => office.name === name
      );
      return isOfficeOpen && workspaceType !== "companyOffice";
    }

    return name !== existingName || workspaceType !== type;
  };

  const saveEnabled = () => {
    if (workspaceType === "homeOffice") {
      return homeArea && homeOfficeArea && address;
    }
    return address && name;
  };

  return (
    <Popper
      anchorEl={anchorEl}
      isOpen={open}
      style={{ zIndex: 1300, width: isMobile ? "80%" : "600px" }}
      placement="left"
      title="Select a workspace"
      onClose={() => setAnchorEl(null)}
      content={
        <>
          <Grid item container justifyContent="center">
            <ToggleButtons
              value={workspaceType}
              onChange={(value) => {
                setWorkspaceType(value);
                return setNewWorkspace({});
              }}
              buttons={toggleButtons}
            />
          </Grid>
          <Grid item xs={12}>
            {findInputType()}
          </Grid>
          <Grid item container justifyContent="center" spacing={2}>
            <Grid item>
              <Tooltip title="Edit just this office without adding a new one">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onButtonClick(updateCommuteEndpointInfo)}
                  disabled={!saveEnabled()}
                >
                  Change this office
                </Button>
              </Tooltip>
            </Grid>
            {findShowNewOffice() && (
              <Grid item>
                <Tooltip title="Add this office to this time period if you're commuting to this place in addition to the one that's previously shown">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={onButtonClick(createNewCommuteEndpoint)}
                    disabled={!saveEnabled()}
                  >
                    Make this a new office
                  </Button>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </>
      }
    />
  );
};

const CommuteVehicleRowOfficeLabelTooltip = ({
  type,
  name,
  id,
  tempId,
  address,
  daysPerWk,
  activePopper,
  popperAnchorEl,
  editMode,
  index,
  editCommuteScheduleHome,
  commuteHome,
  updateCommuteEndpointInfo,
  onPopperClick,
  createNewCommuteEndpoint,
  commuteEndpoints,
}) => {
  const { palette } = useTheme();

  //TODO: -CLEANUP, -PERFORMANCE- instead of making this fetch in every component, there really needs to be a context that wraps the whole CommuteSchedulesInterface (or even the WorkspaceGraphicCard) that just stores this data when it first loads.
  //It would also store the vehicles data that's getting called in multiple places, all of the DB data needed by this interface should just be loaded into it when it first renders
  //That way we don't have to make a call to the DB for every component that needs this data.
  const [companyOffices, companyOfficesLoading] =
    useEmployeesApiDbData("offices");

  const [isWorkspaceClosed, setIsWorkspaceClosed] = useState(false);

  const { electricRenewablesPercentage = 0 } = commuteHome || {};

  useEffect(() => {
    if (!companyOfficesLoading) {
      const findIsWorkspaceClosed = () => {
        if (type !== "companyOffice") {
          return false;
        }
        const { changes } =
          companyOffices.find((companyOffice) => companyOffice.id === id) || {};

        if (!changes) {
          return false;
        }

        return changes.find(({ status }) => status === "closed");
      };

      setIsWorkspaceClosed(findIsWorkspaceClosed());
    }
  }, [companyOffices, companyOfficesLoading, id, type]);

  const { icon } = useOfficeTypes({
    type,
    iconSize: daysPerWk < 1.5 ? "lg" : "2x",
  });

  return (
    <>
      <Tooltip
        title={
          type === "homeOffice"
            ? `Home offices don't need commute vehicles.${
                electricRenewablesPercentage
                  ? ` ${electricRenewablesPercentage}% of this home office is covered by renewable energy source `
                  : ""
              }`
            : `${name}- ${daysPerWk} days/wk.${
                isWorkspaceClosed
                  ? "This office is now closed and needs to be changed"
                  : ""
              }`
        }
        placement="top"
      >
        <span style={{ height: "100%", width: "100%" }}>
          <ButtonBase
            onClick={onPopperClick(`workspace-${index}`)}
            style={{
              width: "100%",
              display: "block",
              height: "100%",
              color:
                electricRenewablesPercentage && type === "homeOffice"
                  ? palette.secondary.main
                  : "inherit",
            }}
            disabled={!editMode}
          >
            <div style={{ height: "100%", width: "100%" }}>
              <CommuteVehicleInputBox
                styleObj={{
                  backgroundColor: isWorkspaceClosed
                    ? hexToRgba(palette.error.main, 0.7)
                    : "rgba(255, 255, 255, 0.7)",
                }}
              >
                {icon}
              </CommuteVehicleInputBox>
            </div>
          </ButtonBase>
        </span>
      </Tooltip>
      {activePopper === `workspace-${index}` && (
        <EditAddWorkspacePopper
          daysPerWk={daysPerWk}
          anchorEl={popperAnchorEl}
          setAnchorEl={onPopperClick(`workspace-${index}`)}
          type={type}
          id={id}
          commuteEndpoints={commuteEndpoints}
          commuteHome={type === "homeOffice" ? commuteHome : {}}
          name={name}
          address={address}
          open={activePopper === `workspace-${index}`}
          companyOffices={companyOffices}
          createNewCommuteEndpoint={createNewCommuteEndpoint}
          onPopperClick={onPopperClick(`workspace-${index}`)}
          editCommuteScheduleHome={editCommuteScheduleHome}
          updateCommuteEndpointInfo={updateCommuteEndpointInfo}
        />
      )}
    </>
  );
};

const useDropVehicles = (dropFunction) =>
  useDrop(() => ({
    accept: itemTypes.VEHICLE,
    drop: (vehicle) => dropFunction(vehicle),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      vehicle: monitor.getItem(),
    }),
  }));

const SlideArrow = ({ onArrowSelect, onArrowDeselect }) => {
  const { isMobile } = useLayoutHelpers();

  const draggingProps = isMobile
    ? { onTouchStart: onArrowSelect, onTouchEnd: onArrowDeselect }
    : { onMouseDown: onArrowSelect, onMouseUp: onArrowDeselect };

  return (
    <div
      style={{
        position: "absolute",
        top: "-10px",
        left: "calc(50% - 11px)",
        zIndex: 9999,
      }}
    >
      <IconButton size="small" {...draggingProps}>
        <FontAwesomeIcon
          icon={faSortCircle}
          style={{ color: "white" }}
          size="sm"
        />
      </IconButton>
    </div>
  );
};

const TinyCommuteVehicleRow = ({ buildStyleObj, name, daysPerWk }) => (
  <Tooltip title={`${name}- ${daysPerWk} days/wk.`} placement="top">
    <div style={{ position: "relative" }}>
      <CommuteVehicleInputBox
        styleObj={{ ...buildStyleObj(true), height: "10px", padding: 0 }}
      />
    </div>
  </Tooltip>
);

const TinyWorkspaceRow = ({ type, name, daysPerWk, buildStyleObj }) => (
  <WorkspaceScheduleBlock type={type} daysPerWk={daysPerWk}>
    <Grid
      container
      style={{ height: "100%", marginBottom: "5px", marginTop: "2px" }}
      alignItems="stretch"
    >
      <Grid item sm={2} style={{ overflowY: "hidden" }}>
        <div style={{ height: "100%", width: "100%" }}>
          <CommuteVehicleInputBox
            styleObj={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}
          ></CommuteVehicleInputBox>
        </div>
      </Grid>
      <Grid item sm={10}>
        <div style={{ position: "relative" }}>
          <CommuteVehicleInputBox
            styleObj={{ ...buildStyleObj(true), height: "10px" }}
          />
        </div>
      </Grid>
    </Grid>
  </WorkspaceScheduleBlock>
);

const TinyRow = ({
  isDeletable,
  removeRowQuestionText,
  removeRow,
  cancelRowDelete,
  children,
}) => {
  const theme = useTheme();

  return (
    <div style={{ position: "relative" }}>
      {isDeletable && (
        <div
          style={{
            position: "absolute",
            zIndex: 99999,
            bottom: "100%",
            left: "calc(50% - 129px + 2px)",
          }}
        >
          <Paper style={{ padding: theme.spacing(1) }}>
            <Grid container direction="column">
              <Grid item>
                <Typography variant="subtitle2">
                  {removeRowQuestionText}
                </Typography>
              </Grid>
              <Grid item container justifyContent="center" spacing={4}>
                <Grid item>
                  <IconButton size="small" onClick={() => removeRow()}>
                    <CheckIcon color="secondary" />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton size="small" onClick={() => cancelRowDelete()}>
                    <CancelIcon color="error" />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </div>
      )}
      {children}
    </div>
  );
};

//TODO: CLEANUP- We could probably make this from a modified version of the EditAddWorkspacesPopper since I copied over alot of the functionality
const EditAddVehiclePopper = ({
  open,
  anchorEl,
  onPopperClick,
  id,
  employeePersonalVehicles,
  daysPerWk,
  name,
  updateCommuteEndpointVehicle,
  createNewVehicle,
}) => {
  //TODO: these values should be coming from a context so they don't have to be loaded each time
  const [companyVehicles, companyVehiclesLoading] =
    useEmployeesApiDbData("vehicles");

  const [selectedVehicle, setSelectedVehicle] = useState({ name, id });
  const [availableVehicles, setAvailableVehicles] = useState([]);

  useEffect(() => {
    if (!companyVehiclesLoading) {
      const allVehicles = [
        ...employeePersonalVehicles,
        ...companyVehicles,
        ...alternateVehicles,
      ];
      setAvailableVehicles(
        allVehicles.filter(({ id: vehicleId }) => vehicleId !== id)
      );
    }
  }, [companyVehiclesLoading, companyVehicles, employeePersonalVehicles, id]);

  const isDifferentVehicle = name !== selectedVehicle.name;

  return (
    <Popper
      anchorEl={anchorEl}
      isOpen={open}
      style={{ zIndex: 1300, maxWidth: "500px" }}
      title="Edit or add a vehicle"
      onClose={onPopperClick}
      content={
        <>
          <Grid item xs={12}>
            <DbAutocomplete
              label="Select a commute vehicle"
              availableOptions={availableVehicles}
              value={selectedVehicle}
              setValue={setSelectedVehicle}
              disableClearable={true}
            />
          </Grid>
          <Grid item container spacing={2} justifyContent="center">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={!isDifferentVehicle}
                onClick={() => {
                  updateCommuteEndpointVehicle({
                    ...selectedVehicle,
                    daysPerWk,
                  });
                  return onPopperClick();
                }}
              >
                Save Changes
              </Button>
            </Grid>
            {isDifferentVehicle && (
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    createNewVehicle({ ...selectedVehicle, daysPerWk });
                    return onPopperClick();
                  }}
                >
                  Add a vehicle
                </Button>
              </Grid>
            )}
          </Grid>
        </>
      }
    />
  );
};

const CommuteVehicleRow = ({
  isDraggable,
  type,
  name,
  daysPerWk,
  id,
  buildStyleObj,
  addVehicleToWorkspace,
  vehiclesCount,
  onVehicleSelect,
  onVehicleDeselect,
  editMode,
  removeVehicleFromWorkspace,
  cancelVehicleDelete,
  onPopperClick,
  index,
  workspaceIndex,
  activePopper,
  popperAnchorEl,
  employeePersonalVehicles,
  updateCommuteEndpointVehicle,
  createNewVehicle,
  ...otherProps
}) => {
  const theme = useTheme();
  const workDayHeightPx = useWorkDayHeightPx();

  //TODO: CLEANUP-, PERFORMANCE- This should come from context
  const [companyVehicles = [], companyVehiclesLoading] =
    useEmployeesApiDbData("vehicles") || [];

  const { color, icon, tooltip } = useVehicleTypeProperties(type) || {};

  const [isVehicleArchived, setIsVehicleArchived] = useState(false);

  useEffect(() => {
    const findIsVehicleArchived = () => {
      if (
        [
          "walkBike",
          "lightRail",
          "regionalRail",
          "intercityRail",
          "carpool",
          "bus",
        ].includes(type)
      ) {
        return false;
      }

      const dbVehicle = [...employeePersonalVehicles, ...companyVehicles].find(
        (vehicle) => vehicle.id === id
      );

      if (!dbVehicle) {
        return true;
      }

      return !!dbVehicle.archived;
    };

    if (!companyVehiclesLoading) {
      setIsVehicleArchived(findIsVehicleArchived());
    }
  }, [
    companyVehicles,
    companyVehiclesLoading,
    id,
    employeePersonalVehicles,
    type,
  ]);

  const [{ isOver }, drop] = useDropVehicles((vehicle) =>
    addVehicleToWorkspace(vehicle, id)
  );

  const isSmall = daysPerWk < 1.5;
  const isTiny = vehiclesCount > 1 ? daysPerWk < 0.5 : daysPerWk < 0.75;

  const iconColor = isVehicleArchived ? theme.palette.error.main : color;

  return (
    <>
      <Box pt={0.125} position="relative">
        {editMode && isDraggable && (
          <SlideArrow
            onArrowSelect={onVehicleSelect}
            onArrowDeselect={onVehicleDeselect}
          />
        )}
      </Box>
      {isTiny ? (
        <TinyRow
          removeRowQuestionText="Would you like to remove this vehicle?"
          removeRow={removeVehicleFromWorkspace}
          cancelRowDelete={cancelVehicleDelete}
          {...otherProps}
        >
          <TinyCommuteVehicleRow
            buildStyleObj={buildStyleObj}
            name={name}
            daysPerWk={daysPerWk}
          />
        </TinyRow>
      ) : (
        <>
          <ButtonBase
            onClick={onPopperClick(
              `workspace-${workspaceIndex}-vehicle-${index}`
            )}
            style={{
              width: "100%",
              display: "block",
              height: "100%",
            }}
            disabled={!editMode}
          >
            <CommuteVehicleInputBox
              refPropObj={{ ref: drop }}
              styleObj={{
                ...buildStyleObj(isOver),
                height: `${
                  daysPerWk * workDayHeightPx -
                  theme.spacing(1.5 / vehiclesCount).slice(0, -2)
                }px`,
              }}
            >
              <Grid
                container
                alignItems="center"
                style={{ height: "100%", width: "100%" }}
                wrap="nowrap"
                spacing={1}
              >
                <Grid item sm={isSmall ? 2 : 3}>
                  <Tooltip
                    title={`${tooltip}${
                      isVehicleArchived
                        ? "- This vehicle is archived and has to be changed"
                        : ""
                    }`}
                  >
                    <div>
                      {isSmall ? (
                        <FontAwesomeIcon
                          icon={icon}
                          size="lg"
                          style={{ color: iconColor }}
                        />
                      ) : (
                        <Avatar style={{ backgroundColor: iconColor }}>
                          <FontAwesomeIcon icon={icon} size="1x" />
                        </Avatar>
                      )}
                    </div>
                  </Tooltip>
                </Grid>
                <Grid item sm={isSmall ? 10 : 9}>
                  <Typography
                    color="inherit"
                    variant={isSmall ? "caption" : "body2"}
                  >
                    {isOver
                      ? "Add another vehicle to this commute day"
                      : `${name}- ${daysPerWk} days/wk.`}
                  </Typography>
                </Grid>
              </Grid>
            </CommuteVehicleInputBox>
          </ButtonBase>
          <EditAddVehiclePopper
            anchorEl={popperAnchorEl}
            open={
              activePopper === `workspace-${workspaceIndex}-vehicle-${index}`
            }
            onPopperClick={onPopperClick(
              `workspace-${workspaceIndex}-vehicle-${index}`
            )}
            employeePersonalVehicles={employeePersonalVehicles}
            id={id}
            name={name}
            daysPerWk={daysPerWk}
            updateCommuteEndpointVehicle={updateCommuteEndpointVehicle}
            createNewVehicle={createNewVehicle}
          />
        </>
      )}
    </>
  );
};

const CommuteVehiclesInputBlock = ({
  vehicles,
  addVehicleToWorkspace,
  onVehicleSelect,
  onVehicleDeselect,
  editMode,
  updateCommuteEndpointVehicle,
  buildStyleObj,
  createNewVehicle,
  ...otherProps
}) => {
  const [{ isOver }, drop] = useDropVehicles((vehicle) =>
    addVehicleToWorkspace(vehicle)
  );

  return (
    <>
      {!vehicles?.length ? (
        <CommuteVehicleInputBox
          refPropObj={{ ref: drop }}
          styleObj={buildStyleObj(isOver)}
        >
          <Typography variant="body2" align="center">
            Drag commute vehicles into this block
          </Typography>
        </CommuteVehicleInputBox>
      ) : (
        <Box
          style={{ height: "100%", width: "100%" }}
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          {vehicles.map((vehicle, idx) => (
            <CommuteVehicleRow
              key={`commute-vehicle-block-${idx}`}
              buildStyleObj={buildStyleObj}
              isDraggable={!!idx}
              addVehicleToWorkspace={addVehicleToWorkspace}
              vehiclesCount={vehicles.length}
              onVehicleSelect={onVehicleSelect(idx)}
              onVehicleDeselect={onVehicleDeselect}
              editMode={editMode}
              index={idx}
              updateCommuteEndpointVehicle={updateCommuteEndpointVehicle(
                vehicle
              )}
              createNewVehicle={createNewVehicle(vehicle.id)}
              {...otherProps}
              {...vehicle}
            />
          ))}
        </Box>
      )}
    </>
  );
};

const WorkspaceDaysPerWkVehiclesDisplay = ({
  name,
  daysPerWk,
  vehicles = [],
  type,
  isDeletable,
  editPairedVehiclesData,
  editMode,
  onWorkspaceSelect,
  onWorkspaceDeselect,
  removeWorkspace,
  cancelWorkspaceDelete,
  onRowEdit,
  index,
  editCommuteScheduleHome,
  commuteHome,
  updateCommuteEndpointInfo,
  onPopperClick,
  ...otherProps
}) => {
  const theme = useTheme();

  const { isMobile } = useLayoutHelpers();

  //WL 08-03-22, TODO: this function was added because vehicle types for vehicles stored in endpoints weren't being saved in the DB
  //While this function might work in most cases, it's easy to see how it's not super reliable
  //Better option would be to save commute endpoints with vehicle types
  const vehiclesWithTypes = vehicles.map((vehicle) => {
    const { type, tonsCo2ePerMile, id } = vehicle;
    if (type) {
      return vehicle;
    }
    if (tonsCo2ePerMile) {
      return { ...vehicle, type: "personal" };
    }
    return { ...vehicle, type: id };
  });

  const { onBlockSelect, onBlockDeselect, onBlockResize } =
    useSlidableDaysPerWkBlock(
      vehiclesWithTypes,
      onRowEdit(editPairedVehiclesData, "id"),
      "id"
    );

  const draggingProps = isMobile
    ? { onTouchMove: onBlockResize, onTouchEnd: onBlockDeselect }
    : {
        onMouseMove: onBlockResize,
        onMouseUp: onBlockDeselect,
        onMouseLeave: onBlockDeselect,
      };

  const isTiny = daysPerWk < 0.5;

  const buildStyleObj = (isOver) => ({
    backgroundColor: isOver
      ? hexToRgba(theme.palette.vehicles.main, 0.7)
      : "rgba(0, 0, 0, 0.7)",
    width: `calc(100% - ${theme.spacing(0.5)})`,
    marginLeft: theme.spacing(0.5),
  });

  return (
    <>
      {editMode && (
        <Box position="relative">
          <SlideArrow
            onArrowSelect={onWorkspaceSelect}
            onArrowDeselect={onWorkspaceDeselect}
          />
        </Box>
      )}
      {isTiny ? (
        <TinyRow
          removeRowQuestionText="Would you like to remove this workspace?"
          removeRow={removeWorkspace}
          cancelRowDelete={cancelWorkspaceDelete}
          vehicles={vehiclesWithTypes}
          isDeletable={isDeletable}
          {...otherProps}
        >
          <TinyWorkspaceRow
            type={type}
            name={name}
            daysPerWk={daysPerWk}
            editCommuteScheduleHome={editCommuteScheduleHome}
            commuteHome={commuteHome}
            updateCommuteEndpointInfo={updateCommuteEndpointInfo}
            onPopperClick={onPopperClick}
            buildStyleObj={buildStyleObj}
            {...otherProps}
          />
        </TinyRow>
      ) : (
        <WorkspaceScheduleBlock
          {...draggingProps}
          type={type}
          daysPerWk={daysPerWk}
        >
          {type === "homeOffice" ? (
            <CommuteVehicleRowOfficeLabelTooltip
              type={type}
              name={name}
              daysPerWk={daysPerWk}
              index={index}
              editMode={editMode}
              editCommuteScheduleHome={editCommuteScheduleHome}
              commuteHome={commuteHome}
              updateCommuteEndpointInfo={updateCommuteEndpointInfo}
              onPopperClick={onPopperClick}
              {...otherProps}
            />
          ) : (
            <Grid container style={{ height: "100%" }} alignItems="stretch">
              <Grid item xs={2}>
                <CommuteVehicleRowOfficeLabelTooltip
                  type={type}
                  name={name}
                  daysPerWk={daysPerWk}
                  index={index}
                  editMode={editMode}
                  editCommuteScheduleHome={editCommuteScheduleHome}
                  commuteHome={commuteHome}
                  updateCommuteEndpointInfo={updateCommuteEndpointInfo}
                  onPopperClick={onPopperClick}
                  {...otherProps}
                />
              </Grid>
              <Grid item xs={10}>
                <CommuteVehiclesInputBlock
                  onVehicleSelect={onBlockSelect}
                  onVehicleDeselect={onBlockDeselect}
                  vehicles={vehiclesWithTypes}
                  editMode={editMode}
                  onPopperClick={onPopperClick}
                  workspaceIndex={index}
                  buildStyleObj={buildStyleObj}
                  {...otherProps}
                />
              </Grid>
            </Grid>
          )}
        </WorkspaceScheduleBlock>
      )}
    </>
  );
};

const CommuteSchedulesInterface = ({
  commuteEndpoints,
  editCommuteEndpoints,
  updatePeriodEndpoints,
  editCommuteScheduleHome,
  editMode,
  commuteHome = {},
  employeePersonalVehicles = [],
}) => {
  const { isMobile } = useLayoutHelpers();
  const [companyVehicles] = useEmployeesApiDbData("vehicles") || [];

  const [activePopper, setActivePopper] = useState("");
  const [popperAnchorEl, setPopperAnchorEl] = useState(null); //TODO: Same comment as below. These two state variables should be in a popper so they don't have to get passed down

  const totalWorkweekDays = commuteEndpoints.reduce(
    (sum, { daysPerWk }) => sum + daysPerWk,
    0
  );
  const otherPopperProps = {
    activePopper,
    setActivePopper,
    popperAnchorEl,
    setPopperAnchorEl,
  };

  //TODO: this should probably be in the context that we need to create for this so that it doesn't have to be passed through the component tree
  const onPopperClick = (popper) => (e) => {
    if (popperAnchorEl && activePopper === popper) {
      setActivePopper(null);
      return setPopperAnchorEl(null);
    }

    setActivePopper(popper);
    return setPopperAnchorEl(e.currentTarget);
  };

  const onRowEdit =
    (editRow, editRowIdentifier) =>
    ([
      { tempId: selectedRowId, daysPerWk: newSelectedDaysPerWk },
      { tempId: adjacentRowId, daysPerWk: newAdjacentDaysPerWk },
    ]) => {
      if (newSelectedDaysPerWk < 0.25 || newAdjacentDaysPerWk < 0.25) {
        const deletableRowId =
          newSelectedDaysPerWk < 0.25 ? selectedRowId : adjacentRowId;
        const expandableRowId =
          newSelectedDaysPerWk < 0.25 ? adjacentRowId : selectedRowId;

        return editRow([
          { [editRowIdentifier]: expandableRowId, isExpandable: true },
          { [editRowIdentifier]: deletableRowId, isDeletable: true },
        ]);
      }

      return editRow([
        { [editRowIdentifier]: selectedRowId, daysPerWk: newSelectedDaysPerWk },
        { [editRowIdentifier]: adjacentRowId, daysPerWk: newAdjacentDaysPerWk },
      ]);
    };

  const {
    onBlockSelect: onWorkspaceSelect,
    onBlockDeselect: onWorkspaceDeselect,
    onBlockResize: onWorkspaceResize,
  } = useSlidableDaysPerWkBlock(
    commuteEndpoints,
    onRowEdit(
      editOfficeDaysPerWeek(commuteEndpoints, editCommuteEndpoints),
      "tempId"
    ),
    "tempId",
    totalWorkweekDays
  );

  const updateEndpointVehicles = ({ tempId }, updatedVehicles) => {
    const updatedEndpoints = commuteEndpoints.map((endpoint) => {
      if (endpoint.tempId === tempId) {
        return { ...endpoint, vehicles: updatedVehicles };
      }

      return endpoint;
    });

    return editCommuteEndpoints(updatedEndpoints);
  };

  const calculateNewSplitDaysPerWk = (daysPerWk) => {
    const splitDaysPerWk = daysPerWk / 2;

    if (!((splitDaysPerWk * 4) % 1)) {
      return [splitDaysPerWk, splitDaysPerWk];
    }

    const newSplitDaysPerWk = Math.ceil(splitDaysPerWk * 4) / 4;
    return [newSplitDaysPerWk, daysPerWk - newSplitDaysPerWk];
  };

  const calculateNewVehiclesDaysPerWk = (
    vehicles,
    oldDaysPerWk,
    newDaysPerWk
  ) => {
    const adjustedDaysPerWk = newDaysPerWk - oldDaysPerWk || 0;
    const largestDaysPerWkVehicle = vehicles.reduce((max, vehicle) =>
      vehicle.daysPerWk > max.daysPerWk ? vehicle : max
    );
    const newVehiclesCorrectDaysPerWk = vehicles.map((vehicle) =>
      vehicle.id === largestDaysPerWkVehicle.id
        ? { ...vehicle, daysPerWk: vehicle.daysPerWk + adjustedDaysPerWk }
        : vehicle
    );

    return newVehiclesCorrectDaysPerWk.filter(
      ({ daysPerWk }) => daysPerWk >= 0.25
    );
  };

  //TODO: alot of this function is very similar to the one below it. I'll bet we can combine them.
  const createNewVehicle =
    (selectedEndpointTempId) => (vehicleId) => (newVehicle) => {
      const selectedCommuteEndpoint = commuteEndpoints.find(
        ({ tempId }) => tempId === selectedEndpointTempId
      );
      const { vehicles } = selectedCommuteEndpoint;

      const selectedVehicle = vehicles.find(
        (vehicle) => vehicle.id === vehicleId
      );
      const { daysPerWk: oldVehicleDaysPerWk } = selectedVehicle;

      const [selectedVehicleSplitDaysPerWk, newVehicleDaysPerWk] =
        calculateNewSplitDaysPerWk(oldVehicleDaysPerWk);

      const updatedSelectedVehicle = {
        ...selectedVehicle,
        daysPerWk: selectedVehicleSplitDaysPerWk,
      };

      const updatedNewVehicle = {
        ...newVehicle,
        daysPerWk: newVehicleDaysPerWk,
      };

      const indexOfSelectedVehicle = vehicles.findIndex(
        ({ id }) => id === vehicleId
      );

      const buildNewVehicles = () => {
        if (!indexOfSelectedVehicle) {
          return [
            updatedSelectedVehicle,
            updatedNewVehicle,
            ...vehicles.slice(1),
          ];
        }

        if (indexOfSelectedVehicle === vehicles.length - 1) {
          return [
            ...vehicles.slice(0, indexOfSelectedVehicle),
            updatedSelectedVehicle,
            updatedNewVehicle,
          ];
        }

        return [
          ...vehicles.slice(0, indexOfSelectedVehicle),
          updatedSelectedVehicle,
          updatedNewVehicle,
          ...vehicles.slice(indexOfSelectedVehicle + 1),
        ];
      };

      const updatedCommuteEndpoints = commuteEndpoints.map((endpoint) =>
        endpoint.tempId === selectedEndpointTempId
          ? { ...endpoint, vehicles: buildNewVehicles() }
          : endpoint
      );

      return editCommuteEndpoints(updatedCommuteEndpoints);
    };

  const createNewCommuteEndpoint =
    (selectedEndpointTempId) => (newWorkspace) => {
      const {
        address: newAddress,
        id: newId,
        name: newName,
        type: newType,
      } = newWorkspace;

      const selectedEndpoint = commuteEndpoints.find(
        ({ tempId }) => tempId === selectedEndpointTempId
      );

      const { daysPerWk: oldSelectedEndpointDaysPerWk, vehicles = [] } =
        selectedEndpoint;

      const {
        id: defaultVehicleId,
        name: defaultVehicleName,
        tonsCo2ePerMile: defaultVehicleTonsCo2ePerMile,
        type: defaultVehicleType,
      } = employeePersonalVehicles[0] || companyVehicles[0] || {};

      const defaultCommuteVehicles = vehicles.length
        ? vehicles
        : [
            {
              daysPerWk: oldSelectedEndpointDaysPerWk,
              id: defaultVehicleId,
              name: defaultVehicleName,
              tonsCo2ePerMile: defaultVehicleTonsCo2ePerMile,
              type: defaultVehicleType || "personal",
            },
          ];

      const [selectedEndpointSplitDaysPerWk, newEndpointDaysPerWk] =
        calculateNewSplitDaysPerWk(oldSelectedEndpointDaysPerWk);

      const newSelectedEndpointVehicles = vehicles.length
        ? {
            vehicles: calculateNewVehiclesDaysPerWk(
              vehicles,
              oldSelectedEndpointDaysPerWk,
              selectedEndpointSplitDaysPerWk
            ),
          }
        : {};

      const newEndpointVehicles = defaultCommuteVehicles.length
        ? {
            vehicles: calculateNewVehiclesDaysPerWk(
              defaultCommuteVehicles,
              oldSelectedEndpointDaysPerWk,
              newEndpointDaysPerWk
            ),
          }
        : {};
      const newEndpointTempId = generateTempId();

      const indexOfSelectedEndpoint = commuteEndpoints.findIndex(
        ({ tempId }) => tempId === selectedEndpointTempId
      );

      const updatedSelectedEndpoint = {
        ...selectedEndpoint,
        daysPerWk: selectedEndpointSplitDaysPerWk,
        ...newSelectedEndpointVehicles,
      };
      const newEndpoint = {
        ...selectedEndpoint,
        address: newAddress,
        daysPerWk: newEndpointDaysPerWk,
        id: newId,
        tempId: newEndpointTempId,
        name: newName,
        type: newType,
        ...newEndpointVehicles,
      };

      const buildNewCommuteEndpoints = () => {
        if (!indexOfSelectedEndpoint) {
          return [
            updatedSelectedEndpoint,
            newEndpoint,
            ...commuteEndpoints.slice(1),
          ];
        }

        if (indexOfSelectedEndpoint === commuteEndpoints.length - 1) {
          return [
            ...commuteEndpoints.slice(0, indexOfSelectedEndpoint),
            updatedSelectedEndpoint,
            newEndpoint,
          ];
        }

        return [
          ...commuteEndpoints.slice(0, indexOfSelectedEndpoint),
          updatedSelectedEndpoint,
          newEndpoint,
          ...commuteEndpoints.slice(indexOfSelectedEndpoint + 1),
        ];
      };

      return editCommuteEndpoints(buildNewCommuteEndpoints());
    };

  const updateCommuteEndpointInfo =
    ({ tempId }) =>
    (newValuesObj) => {
      const updatedEndpoints = commuteEndpoints.map((endpoint) => {
        if (endpoint.tempId === tempId) {
          return { ...endpoint, ...newValuesObj };
        }

        return endpoint;
      });

      return editCommuteEndpoints(updatedEndpoints);
    };

  const updateCommuteEndpointVehicle =
    ({ tempId }) =>
    ({ id: oldVehicleId }) =>
    ({
      daysPerWk,
      name: newVehicleName,
      id: newVehicleId,
      tonsCo2ePerMile: newVehicleTonsCo2ePerMile,
      type: newVehicleType,
    }) => {
      const updatedEndpoints = commuteEndpoints.map((endpoint) => {
        if (endpoint.tempId === tempId) {
          const { vehicles } = endpoint;

          const updatedVehicles = vehicles.map((vehicle) => {
            if (vehicle.id === oldVehicleId) {
              return {
                daysPerWk,
                name: newVehicleName,
                id: newVehicleId,
                tonsCo2ePerMile: newVehicleTonsCo2ePerMile,
                type: newVehicleType,
              };
            }

            return vehicle;
          });

          return {
            ...endpoint,
            vehicles: updatedVehicles,
          };
        }

        return endpoint;
      });

      return editCommuteEndpoints(updatedEndpoints);
    };

  const addVehicleToWorkspace =
    (endpointTempId) => (newVehicle, splitVehicleId) => {
      if (!splitVehicleId) {
        const endpointsUpdater = (commuteEndpoints) =>
          commuteEndpoints.map((endpoint) => {
            if (endpoint.tempId !== endpointTempId) {
              return endpoint;
            }

            const { daysPerWk } = endpoint;
            return {
              ...endpoint,
              vehicles: [{ ...newVehicle, daysPerWk }],
            };
          });

        return updatePeriodEndpoints(endpointsUpdater);
      }

      const endpointsUpdater = (commuteEndpoints) =>
        commuteEndpoints.map((endpoint) => {
          if (endpoint.tempId !== endpointTempId) {
            return endpoint;
          }

          const { vehicles = [] } = endpoint;

          const doesVehicleAlreadyExist = vehicles.find(
            ({ id }) => id === newVehicle.id
          );

          if (doesVehicleAlreadyExist) {
            return endpoint;
          }

          const { daysPerWk = 0 } =
            vehicles.find(({ id }) => id === splitVehicleId) || {};

          const [splitVehicleDaysPerWk, newVehicleDaysPerWk] =
            calculateNewSplitDaysPerWk(daysPerWk);

          const updatedVehicles = vehicles.map((vehicle) => {
            if (vehicle.id !== splitVehicleId) {
              return vehicle;
            }
            return { ...vehicle, daysPerWk: splitVehicleDaysPerWk };
          });
          const newVehicles = [
            ...updatedVehicles,
            { ...newVehicle, daysPerWk: newVehicleDaysPerWk },
          ];

          return { ...endpoint, vehicles: newVehicles };
        });

      return updatePeriodEndpoints(endpointsUpdater);
    };

  const editPairedVehiclesData = (endpoint) => (pairedVehiclesDataArray) => {
    const { vehicles } = endpoint;

    const updatedVehicles = vehicles.map((vehicle) => {
      const vehicleUpdateObj = pairedVehiclesDataArray.find(
        ({ id }) => id === vehicle.id
      );

      if (!vehicleUpdateObj) {
        return vehicle;
      }

      return { ...vehicle, ...vehicleUpdateObj };
    });

    return updateEndpointVehicles(endpoint, updatedVehicles);
  };

  const removeVehicleFromWorkspace = (endpoint) => () => {
    const { vehicles } = endpoint;
    const { id: deletableId } = vehicles.find(({ isDeletable }) => isDeletable);

    const updatedVehicles = vehicles
      .filter((vehicle) => vehicle.id !== deletableId)
      .map((vehicle) => {
        const { isExpandable, daysPerWk, ...otherProps } = vehicle;
        if (!isExpandable) {
          return vehicle;
        }
        return { ...otherProps, daysPerWk: daysPerWk + 0.25 };
      });

    return updateEndpointVehicles(endpoint, updatedVehicles);
  };

  const cancelVehicleDelete = (endpoint) => () => {
    const { vehicles } = endpoint;
    const updatedVehicles = vehicles.map(
      ({ isExpandable, isDeletable, ...otherProps }) => ({ ...otherProps })
    );
    return updateEndpointVehicles(endpoint, updatedVehicles);
  };

  const removeWorkspace = () =>
    editCommuteEndpoints(
      commuteEndpoints
        .filter(({ isDeletable }) => !isDeletable)
        .map((endpoint) => {
          const { isExpandable, daysPerWk, vehicles, ...otherFields } =
            endpoint;

          if (!isExpandable) {
            return endpoint;
          }

          if (vehicles && vehicles.length) {
            const largestDaysPerWkVehicle = vehicles.reduce((max, vehicle) =>
              vehicle.daysPerWk > max.daysPerWk ? vehicle : max
            );
            const newVehiclesCorrectDaysPerWk = vehicles.map((vehicle) =>
              vehicle.id === largestDaysPerWkVehicle.id
                ? { ...vehicle, daysPerWk: vehicle.daysPerWk + 0.25 }
                : vehicle
            );
            const filterVehiclesWithLessThanQuarterDay =
              newVehiclesCorrectDaysPerWk.filter(
                ({ daysPerWk }) => daysPerWk >= 0.25
              );

            return {
              ...otherFields,
              daysPerWk: daysPerWk + 0.25,
              vehicles: filterVehiclesWithLessThanQuarterDay,
            };
          }

          return { ...otherFields, vehicles, daysPerWk: daysPerWk + 0.25 };
        })
    );

  const cancelWorkspaceDelete = () =>
    editCommuteEndpoints(
      commuteEndpoints.map(({ isExpandable, isDeletable, ...otherProps }) => ({
        ...otherProps,
      }))
    );

  const draggingProps = isMobile
    ? { onTouchMove: onWorkspaceResize, onTouchEnd: onWorkspaceDeselect }
    : {
        onMouseMove: onWorkspaceResize,
        onMouseUp: onWorkspaceDeselect,
        onMouseLeave: onWorkspaceDeselect,
      };

  return (
    <WorkspaceScheduleColumn
      weekendDays={7 - totalWorkweekDays}
      editMode={editMode}
      {...draggingProps}
    >
      {commuteEndpoints.map((endpoint, idx) => (
        <WorkspaceDaysPerWkVehiclesDisplay
          key={`office-commute-vehicles-block-${endpoint.tempId}`}
          addVehicleToWorkspace={addVehicleToWorkspace(endpoint.tempId)}
          editPairedVehiclesData={editPairedVehiclesData(endpoint)}
          cancelVehicleDelete={cancelVehicleDelete(endpoint)}
          removeVehicleFromWorkspace={removeVehicleFromWorkspace(endpoint)}
          editMode={editMode}
          onWorkspaceSelect={onWorkspaceSelect(idx)}
          onWorkspaceDeselect={onWorkspaceDeselect}
          removeWorkspace={removeWorkspace}
          cancelWorkspaceDelete={cancelWorkspaceDelete}
          onRowEdit={onRowEdit}
          index={idx}
          editCommuteScheduleHome={editCommuteScheduleHome}
          commuteHome={commuteHome}
          updateCommuteEndpointInfo={updateCommuteEndpointInfo(endpoint)}
          onPopperClick={onPopperClick}
          employeePersonalVehicles={employeePersonalVehicles}
          updateCommuteEndpointVehicle={updateCommuteEndpointVehicle(endpoint)}
          createNewCommuteEndpoint={createNewCommuteEndpoint(endpoint.tempId)}
          createNewVehicle={createNewVehicle(endpoint.tempId)}
          commuteEndpoints={commuteEndpoints}
          {...endpoint}
          {...otherPopperProps}
        />
      ))}
    </WorkspaceScheduleColumn>
  );
};
export default CommuteSchedulesInterface;
