import React, { useState } from "react";
import * as Sentry from "@sentry/react";

import {
  Box,
  Grid,
  Typography,
  ButtonBase,
  StyledEngineProvider,
  InputAdornment,
  ThemeProvider,
} from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTree,
  faSeedling,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import { faTrees } from "@fortawesome/pro-solid-svg-icons";

import { tonsToLbs } from "@aclymatepackages/converters";
import { formatDollars } from "@aclymatepackages/formatters";
import { DefaultPaper, TextField } from "@aclymatepackages/atoms/";
import { useLayoutHelpers } from "@aclymatepackages/themes";
import { numbersRegExpTest, emailRegExpTest } from "@aclymatepackages/reg-exp";
import { mergeDarkTheme, mainTheme } from "@aclymatepackages/themes";

import OffsetsPurchaseFlow from "./OffsetsPurchaseFlow";
import PurchaseInputSection from "./PurchaseInputSection";
import MyAclymateCta from "./MyAclymateCta";

// import { lastYearsFestivarianTravelDataSummary } from "../../helpers/components/events";
import { fetchOurApi } from "../../helpers/utils/apiCalls";
import { analyticsTrack } from "../../helpers/analytics";

const EmailInput = ({
  editServerData,
  individual,
  event,
  type,
  ...otherProps
}) => {
  const { id: eventId } = event;
  const { id: individualId } = individual;
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const onNextStep = async () => {
    const { error } = await fetchOurApi({
      path: "/events/update-partner-email",
      method: "POST",
      data: { email, partnerId: individualId, eventId, type },
      callback: (res) => res,
    });

    if (error) {
      return setEmailError("An error occurred with your email");
    }

    return editServerData("attendee")({ ...individual, email });
  };

  return (
    <PurchaseInputSection
      title="Tell us where to send your receipt"
      input={
        <TextField
          label="Enter your email address"
          value={email}
          setValue={setEmail}
          helperText={emailError}
          error={!!emailError}
        />
      }
      onNextStep={onNextStep}
      nextStepDisabled={!emailRegExpTest(email)}
      {...otherProps}
    />
  );
};

const PriceOptionCard = ({
  title,
  purchaseDollars,
  icon,
  isSelected,
  onClick,
}) => {
  const { theme } = useLayoutHelpers();
  const { palette } = theme;

  return (
    <Grid item>
      <ButtonBase onClick={onClick} style={{ width: "100%", height: "100%" }}>
        <DefaultPaper style={{ width: "100%", height: "100%" }}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <FontAwesomeIcon
                icon={icon}
                size="3x"
                style={{
                  color: isSelected
                    ? palette.secondary.main
                    : palette.backgroundGray.main,
                }}
              />
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" align="center">
                {title}
              </Typography>
              <Typography
                variant="caption"
                display="block"
                align="center"
                color="textSecondary"
              >
                {formatDollars(purchaseDollars)}
              </Typography>
            </Grid>
          </Grid>
        </DefaultPaper>
      </ButtonBase>
    </Grid>
  );
};

const ProjectVolumeSelection = ({
  purchaseDescription,
  displayCost,
  totalEventLbs,
  project,
  groupSize,
  purchaseDollars,
  lbsToPurchase,
  setLbsToPurchase,
  setPurchaseDollars,
  setTypeOfPurchase,
  typeOfPurchase,
  setStripeData,
  stripeChargeId,
  negativeFootprint,
  stripeCustomerId,
  ...otherProps
}) => {
  const { isMobile } = useLayoutHelpers();
  const { totalThousandLbsCost } = project;

  const [stripeDataLoading, setStripeDataLoading] = useState(false);

  //TODO: figure out a better way to do this
  // const { avgTotalEventTons } = lastYearsFestivarianTravelDataSummary();
  const avgTotalEventTons = 0.5; //This is a completely made up number until we figure out a better way to do this
  const avgTotalEventLbs = tonsToLbs(avgTotalEventTons);

  const secondOptionMultiplier = !groupSize ? 2 : groupSize + 1;
  const thirdOptionMultiplier = !groupSize ? 3 : (groupSize + 1) * 2;

  const buildDisplayCostOptions = () => {
    if (negativeFootprint) {
      return [
        {
          title: "Small Impact",
          purchaseDollars: displayCost,
          lbsCarbon: avgTotalEventLbs,
          icon: faSeedling,
          offsetVolumeType: "single",
        },
        {
          title: "Medium Impact",
          purchaseDollars: displayCost * 5,
          lbsCarbon: avgTotalEventLbs * 5,
          icon: faTree,
          offsetVolumeType: "double",
        },
        {
          title: "Large Impact",
          purchaseDollars: displayCost * 10,
          lbsCarbon: avgTotalEventLbs * 10,
          icon: faTrees,
          offsetVolumeType: "triple",
        },
        {
          title: "Custom Amount",
          icon: faDollarSign,
          offsetVolumeType: "custom",
        },
      ];
    }

    return [
      {
        title: "Your individual footprint",
        purchaseDollars: displayCost,
        lbsCarbon: totalEventLbs,
        icon: faSeedling,
        offsetVolumeType: "single",
        multiplier: 1,
      },
      {
        title: !groupSize ? "Double your footprint" : "Your whole group",
        purchaseDollars: displayCost * secondOptionMultiplier,
        lbsCarbon: totalEventLbs * secondOptionMultiplier,
        icon: faTree,
        offsetVolumeType: "double",
        multiplier: secondOptionMultiplier,
      },
      {
        title: !groupSize ? "Triple your footprint" : "Double your whole group",
        purchaseDollars: displayCost * thirdOptionMultiplier,
        lbsCarbon: totalEventLbs * thirdOptionMultiplier,
        icon: faTrees,
        offsetVolumeType: "whole",
        multiplier: thirdOptionMultiplier,
      },
    ];
  };

  const displayCostOptions = buildDisplayCostOptions();

  const setPaymentIntent = ({ lbsToPurchase, purchaseDollars, multiplier }) => {
    setStripeDataLoading(true);

    if (!stripeChargeId) {
      return fetchOurApi({
        path: "/stripe/create-payment-intent",
        method: "POST",
        data: {
          project,
          lbsToPurchase,
          multiplier,
          stripeCustomerId,
          description: purchaseDescription,
        },
        callback: ({ clientSecret, stripeChargeId }) => {
          setStripeDataLoading(false);
          return setStripeData({
            clientSecret,
            stripeChargeId,
            paymentIntentDollars: purchaseDollars,
          });
        },
      });
    }

    return fetchOurApi({
      path: "/stripe/update-payment-intent",
      method: "POST",
      data: { project, lbsToPurchase, multiplier, stripeChargeId },
      callback: () => setStripeDataLoading(false),
    });
  };

  const onOptionSelect = ({
    purchaseDollars,
    offsetVolumeType,
    lbsCarbon,
    multiplier,
  }) => {
    setTypeOfPurchase(offsetVolumeType);

    if (offsetVolumeType === "custom") {
      setPurchaseDollars(0);
      return setStripeData({});
    }

    setPurchaseDollars(purchaseDollars);
    setLbsToPurchase(lbsCarbon);

    return setPaymentIntent({
      purchaseDollars,
      lbsToPurchase: lbsCarbon,
      multiplier,
    });
  };

  const isNextStepDisabled = () => {
    if (!typeOfPurchase) {
      return true;
    }

    if (typeOfPurchase === "custom") {
      return Number(purchaseDollars) < 1 || !numbersRegExpTest(purchaseDollars);
    }

    return !purchaseDollars || !stripeChargeId || stripeDataLoading;
  };

  const onNextStep = () => {
    if (typeOfPurchase !== "custom") {
      return;
    }

    const lbsToPurchase = (purchaseDollars / totalThousandLbsCost) * 1000;

    setLbsToPurchase(lbsToPurchase);
    return setPaymentIntent({ lbsToPurchase, purchaseDollars });
  };

  return (
    <PurchaseInputSection
      title="How much of an impact would you like to make?"
      input={
        <Grid item container direction="column" spacing={2}>
          <Grid
            item
            container
            spacing={2}
            justifyContent="center"
            alignItems="stretch"
            direction={isMobile ? "column" : "row"}
            wrap="nowrap"
          >
            {displayCostOptions.map(
              ({ purchaseDollars, offsetVolumeType, ...option }, idx) => (
                <PriceOptionCard
                  key={`offset-display-cost-option-${idx}`}
                  onClick={() =>
                    onOptionSelect({
                      purchaseDollars,
                      offsetVolumeType,
                      ...option,
                    })
                  }
                  purchaseDollars={purchaseDollars}
                  isSelected={typeOfPurchase === offsetVolumeType}
                  {...option}
                />
              )
            )}
          </Grid>
          {typeOfPurchase === "custom" && (
            <Grid item container justifyContent="center">
              <Grid item md={8} sm={10} xs={12}>
                <TextField
                  label="Select how much you want to pay for offsets"
                  value={purchaseDollars || ""}
                  setValue={setPurchaseDollars}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachMoneyIcon />
                      </InputAdornment>
                    ),
                  }}
                  error={
                    purchaseDollars &&
                    (purchaseDollars < 1 || !numbersRegExpTest(purchaseDollars))
                  }
                  helperText={
                    !!purchaseDollars &&
                    purchaseDollars < 1 &&
                    "You must purchase at least $1 of offsets"
                  }
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      }
      nextStepDisabled={isNextStepDisabled()}
      onNextStep={onNextStep}
      {...otherProps}
    />
  );
};

const PurchaseThanks = ({ slug, thanksImage }) => {
  const { isMedium } = useLayoutHelpers();

  return (
    <Box style={{ height: "100%", overflowX: "hidden", overflowY: "auto" }}>
      <Grid
        container
        direction="column"
        spacing={4}
        alignItems="center"
        wrap="nowrap"
      >
        <Grid item sm={6}>
          <img
            src={thanksImage || "/images/purchase-thanks.svg"}
            style={{ width: "100%" }}
            alt="Thanks for purchasing offsets from Aclymate"
          />
        </Grid>
        <Grid item>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={isMedium ? mainTheme : mergeDarkTheme}>
              <Typography variant="h4" align="center" color="textPrimary">
                The Planet thanks you for your contribution
              </Typography>
              <Typography
                variant="subtitle1"
                align="center"
                color="textSecondary"
              >
                You should have received an email to confirm that your offsets
                have been retired.
              </Typography>
            </ThemeProvider>
          </StyledEngineProvider>
        </Grid>
        <Grid item sm={11}>
          <MyAclymateCta />
        </Grid>
      </Grid>
    </Box>
  );
};

const EventOffsetPurchase = ({
  totalEventTonsCo2e,
  project,
  event,
  individual,
  groupSize,
  editServerData,
  negativeFootprint,
  surveyType,
}) => {
  const { name: eventName } = event || {};
  const { stripeCustomerId, name, email, isEmailOptIn } = individual || {};
  const { slug, displayCost, name: projectName } = project;

  const [stripeData, setStripeData] = useState({});
  const [lbsToPurchase, setLbsToPurchase] = useState(0);
  const [purchaseDollars, setPurchaseDollars] = useState(0);
  const [typeOfPurchase, setTypeOfPurchase] = useState("");
  const { stripeChargeId } = stripeData;

  const emailInputRow = email
    ? []
    : [
        {
          Component: EmailInput,
          props: { editServerData, individual, event, type: surveyType },
        },
      ];

  const buildDisplayCost = () => {
    if (negativeFootprint || displayCost < 1) {
      return 1;
    }

    return displayCost;
  };

  const purchaseRows = [
    {
      Component: ProjectVolumeSelection,
      props: {
        purchaseDescription: `${eventName}- ${projectName}`,
        totalEventLbs: tonsToLbs(totalEventTonsCo2e),
        displayCost: buildDisplayCost(),
        project,
        purchaseDollars,
        lbsToPurchase,
        setLbsToPurchase,
        setPurchaseDollars,
        setTypeOfPurchase,
        typeOfPurchase,
        setStripeData,
        groupSize,
        stripeChargeId,
        negativeFootprint,
        stripeCustomerId,
      },
    },
    ...emailInputRow,
  ];

  const groupOffsetPurchaseDataObj =
    negativeFootprint || surveyType !== "attendee"
      ? {}
      : {
          groupOffsetPurchaseData: {
            groupSize,
            typeOfPurchase,
          },
        };

  const purchaseCallback = async () => {
    const fetchData = {
      surveyType,
      lbsToPurchase,
      purchaseDollars,
      stripeChargeId,
      event,
      individual,
      project,
      ...groupOffsetPurchaseDataObj,
    };

    const callbackFunction = (res) => {
      const { success, error, offset } = res;
      if (error) {
        Sentry.setContext("Event Individual", individual);
        Sentry.setContext("Stripe Data", stripeData);
        Sentry.setContext("Purchase data", {
          projectSlug: slug,
          lbsToPurchase,
          purchaseDollars,
        });
        Sentry.captureException(error);
      }

      if (success) {
        editServerData("offsets")([offset]);
      }

      if (isEmailOptIn) {
        analyticsTrack("Event Offsets Purchase", {
          eventName,
          name,
          email,
          purchaseDollars,
        });
      }

      return res;
    };

    if (surveyType === "attendee") {
      return await fetchOurApi({
        path: "/individuals/event-offset-purchase",
        method: "POST",
        data: fetchData,
        callback: callbackFunction,
      });
    }

    return await fetchOurApi({
      path: "/events/partner-offset-purchase",
      method: "POST",
      data: fetchData,
      callback: callbackFunction,
    });
  };

  return (
    <OffsetsPurchaseFlow
      project={project}
      thanksStep={<PurchaseThanks {...event} />}
      dataEntryRows={purchaseRows}
      inputData={{ lbsToPurchase, purchaseDollars }}
      stripeData={stripeData}
      purchaseCallback={purchaseCallback}
      noFooter
      noPrice
      noVolume
    />
  );
};
export default EventOffsetPurchase;
