import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";

import { useTheme } from "@mui/styles";
import { Box, Grid, Typography, ButtonBase, Paper } from "@mui/material";

import { hexToRgba } from "@aclymatepackages/converters";
import { ucFirstLetters } from "@aclymatepackages/formatters";

import PrimaryView from "./views/primary/PrimaryView";
import OfficesView from "./views/offices/OfficesView";
import EmployeesView from "./views/employees/EmployeesView";
import VendorsView from "./views/vendors/VendorsView";

import ScrollingImageHeaderLayout from "../../layouts/ScrollingImageHeaderLayout";

import { useCompletedOnboardingSteps } from "../../../helpers/hooks/companyData";
import useEmissionsContext from "../../../helpers/contexts/emissions";
import { analyticsPage } from "../../../helpers/analytics";
import ErrorBoundary from "../../atoms/ErrorBoundary";

const ColoredDashboardBlock = ({
  type,
  label,
  content,
  emissionsLoading,
  isSetup,
  isSelected,
  href,
}) => {
  const theme = useTheme();

  const [hover, setHover] = useState(false);

  const color =
    type === "primary"
      ? theme.palette["summary"].main
      : theme.palette[type].main;

  return (
    <Link
      style={{
        width: `25%`,
        textDecoration: "none",
      }}
      to={href || `/platform/company/dashboard/${type}`}
    >
      <ButtonBase
        style={{
          width: `100%`,
          display: "block",
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Box style={{ backgroundColor: "white" }}>
          <Paper
            elevation={isSelected || !hover ? 0 : 3}
            square
            style={{
              height: "89px",
              backgroundColor: hexToRgba(color, isSelected ? 0.1 : 1),
              color: isSelected ? theme.palette.text.primary : "white",
              position: "relative",
              padding: theme.spacing(2, 3),
            }}
          >
            {emissionsLoading ? (
              <Typography color="inherit" variant="h5" align="center">
                Loading...
              </Typography>
            ) : (
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                direction="column"
                style={{ height: "100%" }}
                wrap="nowrap"
              >
                {!isSetup ? (
                  <Grid item>
                    <Typography
                      variant={isSelected ? "h3" : "button"}
                      align="center"
                    >
                      {isSelected
                        ? ucFirstLetters(label || type)
                        : `Click to set up your company's ${label || type}`}
                    </Typography>
                  </Grid>
                ) : (
                  <>
                    {isSelected ? (
                      <Typography variant="h3" color="inherit">
                        {`${ucFirstLetters(label || type)} View`}
                      </Typography>
                    ) : (
                      content || (
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Grid item>
                            <Typography variant="h3">
                              {ucFirstLetters(type)}
                            </Typography>
                          </Grid>
                        </Grid>
                      )
                    )}
                  </>
                )}
              </Grid>
            )}
          </Paper>
        </Box>
      </ButtonBase>
    </Link>
  );
};

const DashboardInfoRow = ({ pageLoading, selectedDashboard }) => {
  const [
    { officesComplete, vendorsComplete, employeesComplete },
    onboardingLoading,
  ] = useCompletedOnboardingSteps();

  const blockLoading = pageLoading || onboardingLoading;

  const dashBoardInfoBlocks = [
    {
      type: "primary",
      isSetup: true,
      href: "/platform/company/dashboard",
    },
    {
      type: "offices",
      isSetup: officesComplete,
      label: "workspaces",
    },
    {
      type: "employees",
      isSetup: employeesComplete,
    },
    {
      type: "vendors",
      isSetup: vendorsComplete,
    },
  ];

  return (
    <>
      {dashBoardInfoBlocks.map(({ type, ...blockProps }, idx) => (
        <ColoredDashboardBlock
          key={`dashboard-colored-block-${idx}`}
          emissionsLoading={blockLoading}
          isSelected={type === selectedDashboard}
          type={type}
          {...blockProps}
        />
      ))}
    </>
  );
};

const DashboardBlock = () => {
  const theme = useTheme();
  const { view } = useParams();

  const {
    recurringEmissionsLoading,
    allEmissions,
    taggedOfficeTransactions,
    taggedEmployeesTransactions,
  } = useEmissionsContext();

  const pageLoading = recurringEmissionsLoading;

  useEffect(() => {
    analyticsPage("dashboard");
  }, []);

  //TODO: Figure out how to make this work
  // useEffect(() => {
  //   if (!showOnboardingPageLoading && showOnboardingPage) {
  //     setRedirect("/platform/company/onboarding");
  //   }
  // }, [setRedirect, showOnboardingPage, showOnboardingPageLoading]);
  const selectedDashboard = view || "primary";

  const dashboardViews = {
    primary: (
      <PrimaryView allEmissions={allEmissions} viewLoading={pageLoading} />
    ),
    offices: (
      <OfficesView
        transactions={taggedOfficeTransactions}
        viewLoading={pageLoading}
      />
    ),
    employees: (
      <EmployeesView
        transactions={taggedEmployeesTransactions}
        viewLoading={pageLoading}
      />
    ),
    vendors: <VendorsView viewLoading={pageLoading} />,
  };

  return (
    <ErrorBoundary>
      <ScrollingImageHeaderLayout
        backgroundColor={theme.palette[selectedDashboard].main}
        stickyContent={
          <DashboardInfoRow
            pageLoading={pageLoading}
            selectedDashboard={selectedDashboard}
          />
        }
        stickyBarHeight={89}
      >
        {dashboardViews[selectedDashboard]}
      </ScrollingImageHeaderLayout>
    </ErrorBoundary>
  );
};
export default DashboardBlock;
