import React from "react";
import dayjs from "dayjs";

import { useTheme } from "@mui/styles";
import { Box, Grid, Typography } from "@mui/material";

import { formatDate } from "@aclymatepackages/formatters";

import { useLayoutHelpers } from "../../../helpers/otherHelpers";
import { useWorkDayHeightPx } from "../../../helpers/components/inputs";

const CommuteDaysColumn = ({ daysPerWk }) => {
  const theme = useTheme();
  const { isMobile } = useLayoutHelpers();
  const workDayHeightPx = useWorkDayHeightPx();

  return (
    <Box display="flex" flexDirection="column-reverse" position="relative">
      {[...new Array(7)].map((_, idx) => {
        const isFullWorkDay = daysPerWk - idx >= 1;
        const isPartialDay = daysPerWk - idx > 0 && daysPerWk - idx < 1;

        return (
          <Box
            key={`commute-day-${idx}`}
            style={{
              backgroundColor: !daysPerWk
                ? "rgba(0, 0, 0, 0.62)"
                : isFullWorkDay
                ? theme.palette.employees.main
                : theme.palette.travel.main,
              height: `${workDayHeightPx}px`,
              width: "100%",
              color: "white",
              border: "thin solid white",
              boxSizing: "border-box",
              padding: theme.spacing(0, isMobile ? 1 : 2),
            }}
            position="relative"
            display="flex"
            justifyContent="center"
            alignItems="center"
            p={1}
          >
            {isPartialDay && (
              <Box
                position="absolute"
                style={{
                  backgroundColor: theme.palette.employees.main,
                  width: "100%",
                  height: `${workDayHeightPx * (daysPerWk - idx)}px`,
                  bottom: 0,
                }}
              />
            )}
            <Typography
              variant={isMobile ? "body2" : "subtitle1"}
              color="inherit"
              style={{ position: "relative", zIndex: 9999 }}
            >{`Day ${idx + 1}`}</Typography>
          </Box>
        );
      })}
    </Box>
  );
};

const WorkspaceGraphicCard = ({
  employeeName = "",
  endDate,
  startDate,
  daysPerWk,
  graphic,
  actions,
}) => {
  const { isMobile } = useLayoutHelpers();

  const formatTitle = () => {
    if (!endDate || dayjs(endDate).isSame(dayjs(), "day")) {
      return "current weekly commute schedule";
    }

    if (startDate && endDate) {
      return `weekly commute schedule from ${formatDate(
        startDate
      )} to ${formatDate(endDate)}`;
    }

    return `weekly commute schedule before ${formatDate(endDate)}`;
  };

  return (
    <>
      <Grid container direction="column" spacing={isMobile ? 0 : 1}>
        <Grid
          item
          container
          alignItems="center"
          wrap="nowrap"
          justifyContent="space-between"
        >
          <Grid item>
            <Typography variant={isMobile ? "body2" : "subtitle1"}>{`${
              employeeName.split(" ")[0]
            }'s ${formatTitle()}`}</Typography>
          </Grid>
          {actions && (
            <Grid item>
              <Grid container wrap="nowrap" alignItems="center">
                {actions.map((action, idx) => (
                  <Grid key={`workspace-edit-card-${idx}`} item>
                    {action}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item container>
          <Grid item xs={2}>
            <CommuteDaysColumn daysPerWk={daysPerWk} />
          </Grid>
          <Grid item xs={10}>
            {graphic}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default WorkspaceGraphicCard;
